import * as actionTypes from './constants';

const initialState = {
    getSchoolListResponse: {},
    getSchoolByIdResponse: {},
    getBranchByIdResponse: {},
    getBranchTeachersResponse: {},
    getBranchOtherUsersResponse: {},
    getSchoolBranchesResponse: {},
    getSchoolUsersResponse: {},
    createSchoolResponse: {},
    createBranchResponse: {},
    updateSchoolResponse: {},
    updateBranchResponse: {},
    deleteBranchResponse: {},
    deleteSchoolResponse: {},
    getAllSchoolResponse: {},
    getAllBranchesResponse: {},
    getAllTermsResponse: {},
    patchBranchResponse: {},
    patchSchoolResponse: {},
};

const schoolManagementReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SCHOOL_LIST_SUCCESS:
            return {
                ...state,
                getSchoolListResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_SCHOOL_LIST_FAILURE:
            return {
                ...state,
                getSchoolListResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_SCHOOl_DETAILS_SUCCESS:
            return {
                ...state,
                getSchoolByIdResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_SCHOOl_DETAILS_FAILURE:
            return {
                ...state,
                getSchoolByIdResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.GET_SCHOOl_BRANCHES_SUCCESS:
            return {
                ...state,
                getSchoolBranchesResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_SCHOOl_BRANCHES_FAILURE:
            return {
                ...state,
                getSchoolBranchesResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_SCHOOl_USERS_SUCCESS:
            return {
                ...state,
                getSchoolUsersResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_SCHOOl_USERS_FAILURE:
            return {
                ...state,
                getSchoolUsersResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.CREATE_SCHOOL_SUCCESS:
            return {
                ...state,
                createSchoolResponse: { success: true, ...action.payload?.data?.data, message: action.payload.message },
            };
        case actionTypes.CREATE_SCHOOL_FAILURE:
            return {
                ...state,
                createSchoolResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.UPDATE_SCHOOL_SUCCESS:
            return {
                ...state,
                updateSchoolResponse: { success: true, ...action.payload?.data?.data, message: action.payload.message },
            };
        case actionTypes.UPDATE_SCHOOL_FAILURE:
            return {
                ...state,
                updateSchoolResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_BRANCH_DETAILS_SUCCESS:
            return {
                ...state,
                getBranchByIdResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_BRANCH_DETAILS_FAILURE:
            return {
                ...state,
                getBranchByIdResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.GET_BRANCH_TEACHERS_SUCCESS:
            return {
                ...state,
                getBranchTeachersResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_BRANCH_TEACHERS_FAILURE:
            return {
                ...state,
                getBranchTeachersResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_BRANCH_OTHER_USERS_SUCCESS:
            return {
                ...state,
                getBranchOtherUsersResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_BRANCH_OTHER_USERS_FAILURE:
            return {
                ...state,
                getBranchOtherUsersResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.CREATE_BRANCH_SUCCESS:
            return {
                ...state,
                createBranchResponse: { success: true, ...action.payload?.data?.data, message: action.payload.message },
            };
        case actionTypes.CREATE_BRANCH_FAILURE:
            return {
                ...state,
                createBranchResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.UPDATE_BRANCH_SUCCESS:
            return {
                ...state,
                updateBranchResponse: { success: true, ...action.payload?.data?.data, message: action.payload.message },
            };
        case actionTypes.UPDATE_BRANCH_FAILURE:
            return {
                ...state,
                updateBranchResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.DELETE_BRANCH_SUCCESS:
            return {
                ...state,
                deleteBranchResponse: { success: true, ...action.payload?.data?.data, message: action.payload.message },
            };
        case actionTypes.DELETE_BRANCH_FAILURE:
            return {
                ...state,
                deleteBranchResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.DELETE_SCHOOL_SUCCESS:
            return {
                ...state,
                deleteSchoolResponse: { success: true, ...action.payload?.data?.data, message: action.payload.message },
            };
        case actionTypes.DELETE_SCHOOL_FAILURE:
            return {
                ...state,
                deleteSchoolResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_ALL_SCHOOl_SUCCESS:
            return {
                ...state,
                getAllSchoolResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_ALL_SCHOOL_FAILURE:
            return {
                ...state,
                getAllSchoolResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.GET_ALL_BRANCH_SUCCESS:
            return {
                ...state,
                getAllBranchesResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_ALL_BRANCH_RESET:
            return {
                ...state,
                getAllBranchesResponse: {},
            };
        case actionTypes.GET_ALL_BRANCH_FAILURE:
            return {
                ...state,
                getAllBranchesResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.GET_ALL_TERMS_SUCCESS:
            return {
                ...state,
                getAllTermsResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_ALL_TERMS_FAILURE:
            return {
                ...state,
                getAllTermsResponse: { success: false, ...action.payload?.data?.data },
            };

        case actionTypes.PATCH_BRANCH_SUCCESS:
            return {
                ...state,
                patchBranchResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.PATCH_BRANCH_FAILURE:
            return {
                ...state,
                patchBranchResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.PATCH_SCHOOL_SUCCESS:
            return {
                ...state,
                patchSchoolResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.PATCH_SCHOOL_FAILURE:
            return {
                ...state,
                patchSchoolResponse: { success: false, ...action.payload?.data?.data },
            };
        default:
            return state;
    }
};

export default schoolManagementReducers;
