import * as actionTypes from './constants';

const initialState = {
    getTeachersResponse: {},
    getTeacherDetailsResponse: {},
    getAssignedClassResponse: {},
    getIndividualCommunicationResponse: {},
    createTeacherResponse: {},
    createAssignedClassResponse: {},
    deleteAssignedClassResponse: {},
    updateAssignedClassResponse: {},
    updateTeacherResponse: {},
    getSubjectListResponse: {},
    teacherBulkuploadResponse: {},
    teacherBulkUploadLinkResponse: {},
    teacherAttendenceResponse: {},
};

const teacherManagementReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TEACHERS_SUCCESS:
            return {
                ...state,
                getTeachersResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_TEACHERS_FAILURE:
            return {
                ...state,
                getTeachersResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_TEACHER_DETAILS_SUCCESS:
            return {
                ...state,
                getTeacherDetailsResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_TEACHER_DETAILS_FAILURE:
            return {
                ...state,
                getTeacherDetailsResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.CREATE_TEACHER_SUCCESS:
            return {
                ...state,
                createTeacherResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.CREATE_TEACHER_FAILURE:
            return {
                ...state,
                createTeacherResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.UPDATE_TEACHER_SUCCESS:
            return {
                ...state,
                updateTeacherResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.UPDATE_TEACHER_FAILURE:
            return {
                ...state,
                updateTeacherResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_SUBJECT_LIST_SUCCESS:
            return {
                ...state,
                getSubjectListResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_SUBJECT_LIST_FAILURE:
            return {
                ...state,
                getSubjectListResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.TEACHER_BULK_UPLOAD_SUCCESS:
            return {
                ...state,
                teacherBulkuploadResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.TEACHER_BULK_UPLOAD_FAILURE:
            return {
                ...state,
                teacherBulkuploadResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_ASSIGNED_CLASS_SUCCESS:
            return {
                ...state,
                getAssignedClassResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                },
            };
        case actionTypes.GET_ASSIGNED_CLASS_FAILURE:
            return {
                ...state,
                getAssignedClassResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                },
            };
        case actionTypes.CREATE_ASSIGNED_CLASS_SUCCESS:
            return {
                ...state,
                createAssignedClassResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.CREATE_ASSIGNED_CLASS_FAILURE:
            return {
                ...state,
                createAssignedClassResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.DELETE_ASSIGNED_CLASS_SUCCESS:
            return {
                ...state,
                deleteAssignedClassResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.DELETE_ASSIGNED_CLASS_FAILURE:
            return {
                ...state,
                deleteAssignedClassResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.UPDATE_ASSIGNED_CLASS_SUCCESS:
            return {
                ...state,
                updateAssignedClassResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.UPDATE_ASSIGNED_CLASS_FAILURE:
            return {
                ...state,
                updateAssignedClassResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_BULK_UPLOAD_SUCCESS:
            return {
                ...state,
                teacherBulkUploadLinkResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_BULK_UPLOAD_FAILURE:
            return {
                ...state,
                teacherBulkUploadLinkResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_TEACHER_ATTENDENCE_SUCCESS:
            return {
                ...state,
                teacherAttendenceResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                },
            };
        case actionTypes.GET_TEACHER_ATTENDENCE_FAILURE:
            return {
                ...state,
                teacherAttendenceResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                },
            };
        case actionTypes.GET_INDIVIDUAL_COMMUNICATION_SUCCESS:
            return {
                ...state,
                getIndividualCommunicationResponse: {
                    success: true,
                    ...action.payload?.data,
                },
            };
        case actionTypes.GET_INDIVIDUAL_COMMUNICATION_FAILURE:
            return {
                ...state,
                getIndividualCommunicationResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                },
            };
        case actionTypes.GET_INDIVIDUAL_COMMUNICATION_RESET:
            return {
                ...state,
                getIndividualCommunicationResponse: {},
            };
        default:
            return state;
    }
};

export default teacherManagementReducers;
