// POST
export const POST_GROUP_REQUEST = 'POST_GROUP_REQUEST';
export const POST_GROUP_SUCCESS = 'POST_GROUP_SUCCESS';
export const POST_GROUP_FAILURE = 'POST_GROUP_FAILURE';
export const POST_GROUP_RESET = 'POST_GROUP_RESET';

export const POST_MAIL_REQUEST = 'POST_MAIL_REQUEST';
export const POST_MAIL_SUCCESS = 'POST_MAIL_SUCCESS';
export const POST_MAIL_FAILURE = 'POST_MAIL_FAILURE';
export const POST_MAIL_RESET = 'POST_MAIL_RESET';

export const POST_REPLYMAIL_REQUEST = 'POST_REPLYMAIL_REQUEST';
export const POST_REPLYMAIL_SUCCESS = 'POST_REPLYMAIL_SUCCESS';
export const POST_REPLYMAIL_FAILURE = 'POST_REPLYMAIL_FAILURE';
export const POST_REPLYMAIL_RESET = 'POST_REPLYMAIL_RESET';

// PUT
export const PUT_DRAFTMAIL_REQUEST = 'PUT_DRAFTMAIL_REQUEST';
export const PUT_DRAFTMAIL_SUCCESS = 'PUT_DRAFTMAIL_SUCCESS';
export const PUT_DRAFTMAIL_FAILURE = 'PUT_DRAFTMAIL_FAILURE';
export const PUT_DRAFTMAIL_RESET = 'PUT_DRAFTMAIL_RESET';

// PATCH
export const PATCH_STATUSMAIL_REQUEST = 'PATCH_STATUSMAIL_REQUEST';
export const PATCH_STATUSMAIL_SUCCESS = 'PATCH_STATUSMAIL_SUCCESS';
export const PATCH_STATUSMAIL_FAILURE = 'PATCH_STATUSMAIL_FAILURE';
export const PATCH_STATUSMAIL_RESET = 'PATCH_STATUSMAIL_RESET';

// GET
export const GET_MAIL_REQUEST = 'GET_MAIL_REQUEST';
export const GET_MAIL_SUCCESS = 'GET_MAIL_SUCCESS';
export const GET_MAIL_FAILURE = 'GET_MAIL_FAILURE';

export const GET_LATESTMAIL_REQUEST = 'GET_LATESTMAIL_REQUEST';
export const GET_LATESTMAIL_SUCCESS = 'GET_LATESTMAIL_SUCCESS';
export const GET_LATESTMAIL_FAILURE = 'GET_LATESTMAIL_FAILURE';

export const GET_DETAILMAIL_REQUEST = 'GET_DETAILMAIL_REQUEST';
export const GET_DETAILMAIL_SUCCESS = 'GET_DETAILMAIL_SUCCESS';
export const GET_DETAILMAIL_FAILURE = 'GET_DETAILMAIL_FAILURE';

export const GET_SENTMAIL_REQUEST = 'GET_SENTMAIL_REQUEST';
export const GET_SENTMAIL_SUCCESS = 'GET_SENTMAIL_SUCCESS';
export const GET_SENTMAIL_FAILURE = 'GET_SENTMAIL_FAILURE';

export const GET_DRAFTMAIL_REQUEST = 'GET_DRAFTMAIL_REQUEST';
export const GET_DRAFTMAIL_SUCCESS = 'GET_DRAFTMAIL_SUCCESS';
export const GET_DRAFTMAIL_FAILURE = 'GET_DRAFTMAIL_FAILURE';

export const GET_TYPEMAIL_REQUEST = 'GET_TYPEMAIL_REQUEST';
export const GET_TYPEMAIL_SUCCESS = 'GET_TYPEMAIL_SUCCESS';
export const GET_TYPEMAIL_FAILURE = 'GET_TYPEMAIL_FAILURE';

export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';
export const GET_GROUP_LOADING = 'GET_GROUP_LOADING';
export const GET_GROUP_LOADED = 'GET_GROUP_LOADED';

export const GET_INDIVIDUAL_REQUEST = 'GET_INDIVIDUAL_REQUEST';
export const GET_INDIVIDUAL_SUCCESS = 'GET_INDIVIDUAL_SUCCESS';
export const GET_INDIVIDUAL_FAILURE = 'GET_INDIVIDUAL_FAILURE';
export const GET_INDIVIDUAL_LOADING = 'GET_INDIVIDUAL_LOADING';
export const GET_INDIVIDUAL_LOADED = 'GET_INDIVIDUAL_LOADED';
