import * as actionType from './constants';

const initialState = {
    getSessionTypesResponse: {},
    generateTtFileResponse: {},
    uploadTtFileResponse: {},
    updateSessionSubjectResponse: {},
    getTimeTableListResponse: {},
    createHolidayResponse: {},
};

const timetableReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_SESSION_REQUEST:
            return {
                ...state,
            };
        case actionType.GET_SESSION_SUCCESS:
            return {
                ...state,
                getSessionTypesResponse: {
                    success: true,
                    message: action.payload.message,
                    statusCode: action.payload.statusCode,
                    ...action.payload.data.data,
                },
            };
        case actionType.GET_SESSION_FAILURE:
            return {
                ...state,
                getSessionTypesResponse: { success: false, ...action.payload },
            };

        case actionType.GENERATE_FILE_REQUEST:
            return {
                ...state,
            };
        case actionType.GENERATE_FILE_SUCCESS:
            return {
                ...state,
                generateTtFileResponse: {
                    success: true,
                    message: action.payload.message,
                    statusCode: action.payload.statusCode,
                    ...action.payload.data.data,
                },
            };
        case actionType.GENERATE_FILE_FAILURE:
            return {
                ...state,
                generateTtFileResponse: { success: false, ...action.payload },
            };

        case actionType.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                uploadTtFileResponse: {
                    success: true,
                    message: action.payload.message,
                    statusCode: action.payload.statusCode,
                    ...action.payload.data.data,
                },
            };
        case actionType.UPLOAD_FILE_FAILURE:
            return {
                ...state,
                uploadTtFileResponse: {
                    success: false,
                    ...action.payload,
                },
            };

        case actionType.UPDATE_SESSION_SUBJECT_SUCCESS:
            return {
                ...state,
                updateSessionSubjectResponse: {
                    success: true,
                    message: action.payload.message,
                    statusCode: action.payload.statusCode,
                    ...action.payload.data.data,
                },
            };
        case actionType.UPDATE_SESSION_SUBJECT_FAILURE:
            return {
                ...state,
                updateSessionSubjectResponse: {
                    success: false,
                    ...action.payload,
                },
            };

        case actionType.GET_TIME_TABLE_LIST_SUCCESS:
            return {
                ...state,
                getTimeTableListResponse: {
                    success: true,
                    message: action.payload.message,
                    statusCode: action.payload.statusCode,
                    ...action.payload.data.data,
                },
            };
        case actionType.GET_TIME_TABLE_LIST_FAILURE:
            return {
                ...state,
                getTimeTableListResponse: {
                    success: false,
                    ...action.payload,
                },
            };

        case actionType.CREATE_HOLIDAY_SUCCESS:
            return {
                ...state,
                createHolidayResponse: {
                    success: true,
                    message: action.payload.message,
                    statusCode: action.payload.statusCode,
                    ...action.payload.data.data,
                },
            };
        case actionType.CREATE_HOLIDAY_FAILURE:
            return {
                ...state,
                createHolidayResponse: {
                    success: false,
                    ...action.payload,
                },
            };
        default:
            return state;
    }
};

export default timetableReducers;
