export const GET_SCHOOL_LIST_REQUEST = 'GET_SCHOOL_LIST_REQUEST';
export const GET_SCHOOL_LIST_SUCCESS = 'GET_SCHOOL_LIST_SUCCESS';
export const GET_SCHOOL_LIST_FAILURE = 'GET_SCHOOL_LIST_FAILURE';

export const GET_SCHOOl_DETAILS_REQUEST = 'GET_SCHOOl_DETAILS_REQUEST';
export const GET_SCHOOl_DETAILS_SUCCESS = 'GET_SCHOOl_DETAILS_SUCCESS';
export const GET_SCHOOl_DETAILS_FAILURE = 'GET_SCHOOl_DETAILS_FAILURE';

export const GET_SCHOOl_BRANCHES_REQUEST = 'GET_SCHOOl_BRANCHES_REQUEST';
export const GET_SCHOOl_BRANCHES_SUCCESS = 'GET_SCHOOl_BRANCHES_SUCCESS';
export const GET_SCHOOl_BRANCHES_FAILURE = 'GET_SCHOOl_BRANCHES_FAILURE';

export const GET_SCHOOl_USERS_REQUEST = 'GET_SCHOOl_USERS_REQUEST';
export const GET_SCHOOl_USERS_SUCCESS = 'GET_SCHOOl_USERS_SUCCESS';
export const GET_SCHOOl_USERS_FAILURE = 'GET_SCHOOl_USERS_FAILURE';

export const GET_BRANCH_DETAILS_REQUEST = 'GET_BRANCH_DETAILS_REQUEST';
export const GET_BRANCH_DETAILS_SUCCESS = 'GET_BRANCH_DETAILS_SUCCESS';
export const GET_BRANCH_DETAILS_FAILURE = 'GET_BRANCH_DETAILS_FAILURE';

export const GET_BRANCH_TEACHERS_REQUEST = 'GET_BRANCH_TEACHERS_REQUEST';
export const GET_BRANCH_TEACHERS_SUCCESS = 'GET_BRANCH_TEACHERS_SUCCESS';
export const GET_BRANCH_TEACHERS_FAILURE = 'GET_BRANCH_TEACHERS_FAILURE';

export const GET_BRANCH_OTHER_USERS_REQUEST = 'GET_BRANCH_OTHER_USERS_REQUEST';
export const GET_BRANCH_OTHER_USERS_SUCCESS = 'GET_BRANCH_OTHER_USERS_SUCCESS';
export const GET_BRANCH_OTHER_USERS_FAILURE = 'GET_BRANCH_OTHER_USERS_FAILURE';

export const CREATE_SCHOOL_REQUEST = 'CREATE_SCHOOL_REQUEST';
export const CREATE_SCHOOL_SUCCESS = 'CREATE_SCHOOL_SUCCESS';
export const CREATE_SCHOOL_FAILURE = 'CREATE_SCHOOL_FAILURE';

export const CREATE_BRANCH_REQUEST = 'CREATE_BRANCH_REQUEST';
export const CREATE_BRANCH_SUCCESS = 'CREATE_BRANCH_SUCCESS';
export const CREATE_BRANCH_FAILURE = 'CREATE_BRANCH_FAILURE';

export const UPDATE_SCHOOL_REQUEST = 'UPDATE_SCHOOL_REQUEST';
export const UPDATE_SCHOOL_SUCCESS = 'UPDATE_SCHOOL_SUCCESS';
export const UPDATE_SCHOOL_FAILURE = 'UPDATE_SCHOOL_FAILURE';

export const UPDATE_BRANCH_REQUEST = 'UPDATE_BRANCH_REQUEST';
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS';
export const UPDATE_BRANCH_FAILURE = 'UPDATE_BRANCH_FAILURE';

export const DELETE_BRANCH_REQUEST = 'DELETE_BRANCH_REQUEST';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILURE = 'DELETE_BRANCH_FAILURE';

export const DELETE_SCHOOL_REQUEST = 'DELETE_SCHOOL_REQUEST';
export const DELETE_SCHOOL_SUCCESS = 'DELETE_SCHOOL_SUCCESS';
export const DELETE_SCHOOL_FAILURE = 'DELETE_SCHOOL_FAILURE';

export const GET_ALL_SCHOOl_REQUEST = 'GET_ALL_SCHOOl_REQUEST';
export const GET_ALL_SCHOOl_SUCCESS = 'GET_ALL_SCHOOl_SUCCESS';
export const GET_ALL_SCHOOL_FAILURE = 'GET_ALL_SCHOOL_FAILURE';

export const GET_ALL_BRANCH_REQUEST = 'GET_ALL_BRANCH_REQUEST';
export const GET_ALL_BRANCH_SUCCESS = 'GET_ALL_BRANCH_SUCCESS';
export const GET_ALL_BRANCH_FAILURE = 'GET_ALL_BRANCH_FAILURE';
export const GET_ALL_BRANCH_RESET = 'GET_ALL_BRANCH_RESET';

export const GET_ALL_TERMS_REQUEST = 'GET_ALL_TERMS_REQUEST';
export const GET_ALL_TERMS_SUCCESS = 'GET_ALL_TERMS_SUCCESS';
export const GET_ALL_TERMS_FAILURE = 'GET_ALL_TERMS_FAILURE';

export const PATCH_BRANCH_REQUEST = 'PATCH_BRANCH_REQUEST';
export const PATCH_BRANCH_SUCCESS = 'PATCH_BRANCH_SUCCESS';
export const PATCH_BRANCH_FAILURE = 'PATCH_BRANCH_FAILURE';

export const PATCH_SCHOOL_REQUEST = 'PATCH_SCHOOL_REQUEST';
export const PATCH_SCHOOL_SUCCESS = 'PATCH_SCHOOL_SUCCESS';
export const PATCH_SCHOOL_FAILURE = 'PATCH_SCHOOL_FAILURE';
