export const GET_ASSESSMENT_TYPE_REQUEST = 'GET_ASSESSMENT_TYPE_REQUEST';
export const GET_ASSESSMENT_TYPE_SUCCESS = 'GET_ASSESSMENT_TYPE_SUCCESS';
export const GET_ASSESSMENT_TYPE_FAILURE = 'GET_ASSESSMENT_TYPE_FAILURE';

export const CREATE_ASSESSMENT_TYPE_REQUEST = 'CREATE_ASSESSMENT_TYPE_REQUEST';
export const CREATE_ASSESSMENT_TYPE_SUCCESS = 'CREATE_ASSESSMENT_TYPE_SUCCESS';
export const CREATE_ASSESSMENT_TYPE_FAILURE = 'CREATE_ASSESSMENT_TYPE_FAILURE';

export const UPDATE_ASSESSMENT_TYPE_REQUEST = 'UPDATE_ASSESSMENT_TYPE_REQUEST';
export const UPDATE_ASSESSMENT_TYPE_SUCCESS = 'UPDATE_ASSESSMENT_TYPE_SUCCESS';
export const UPDATE_ASSESSMENT_TYPE_FAILURE = 'UPDATE_ASSESSMENT_TYPE_FAILURE';

export const DELETE_ASSESSMENT_TYPE_REQUEST = 'DELETE_ASSESSMENT_TYPE_REQUEST';
export const DELETE_ASSESSMENT_TYPE_SUCCESS = 'DELETE_ASSESSMENT_TYPE_SUCCESS';
export const DELETE_ASSESSMENT_TYPE_FAILURE = 'DELETE_ASSESSMENT_TYPE_FAILURE';

export const GET_ASSESSMENT_REQUEST = 'GET_ASSESSMENT_REQUEST';
export const GET_ASSESSMENT_SUCCESS = 'GET_ASSESSMENT_SUCCESS';
export const GET_ASSESSMENT_FAILURE = 'GET_ASSESSMENT_FAILURE';

export const GET_ASSESSMENT_ID_REQUEST = 'GET_ASSESSMENT_ID_REQUEST';
export const GET_ASSESSMENT_ID_SUCCESS = 'GET_ASSESSMENT_ID_SUCCESS';
export const GET_ASSESSMENT_ID_FAILURE = 'GET_ASSESSMENT_ID_FAILURE';

export const CREATE_ASSESSMENT_REQUEST = 'CREATE_ASSESSMENT_REQUEST';
export const CREATE_ASSESSMENT_SUCCESS = 'CREATE_ASSESSMENT_SUCCESS';
export const CREATE_ASSESSMENT_FAILURE = 'CREATE_ASSESSMENT_FAILURE';

export const UPDATE_ASSESSMENT_REQUEST = 'UPDATE_ASSESSMENT_REQUEST';
export const UPDATE_ASSESSMENT_SUCCESS = 'UPDATE_ASSESSMENT_SUCCESS';
export const UPDATE_ASSESSMENT_FAILURE = 'UPDATE_ASSESSMENT_FAILURE';

export const UPDATE_STATUS_ASSESSMENT_REQUEST = 'UPDATE_STATUS_ASSESSMENT_REQUEST';
export const UPDATE_STATUS_ASSESSMENT_SUCCESS = 'UPDATE_STATUS_ASSESSMENT_SUCCESS';
export const UPDATE_STATUS_ASSESSMENT_FAILURE = 'UPDATE_STATUS_ASSESSMENT_FAILURE';

export const UPDATE_PRACTICE_ASSESSMENT_REQUEST = 'UPDATE_PRACTICE_ASSESSMENT_REQUEST';
export const UPDATE_PRACTICE_ASSESSMENT_SUCCESS = 'UPDATE_PRACTICE_ASSESSMENT_SUCCESS';
export const UPDATE_PRACTICE_ASSESSMENT_FAILURE = 'UPDATE_PRACTICE_ASSESSMENT_FAILURE';

export const DELETE_ASSESSMENT_REQUEST = 'DELETE_ASSESSMENT_REQUEST';
export const DELETE_ASSESSMENT_SUCCESS = 'DELETE_ASSESSMENT_SUCCESS';
export const DELETE_ASSESSMENT_FAILURE = 'DELETE_ASSESSMENT_FAILURE';

export const GET_ASSESSMENT_REPORT_GRADE_REQUEST = 'GET_ASSESSMENT_REPORT_GRADE_REQUEST';
export const GET_ASSESSMENT_REPORT_GRADE_SUCCESS = 'GET_ASSESSMENT_REPORT_GRADE_SUCCESS';
export const GET_ASSESSMENT_REPORT_GRADE_FAILURE = 'GET_ASSESSMENT_REPORT_GRADE_FAILURE';

export const GET_ASSESSMENT_REPORT_REQUEST = 'GET_ASSESSMENT_REPORT_REQUEST';
export const GET_ASSESSMENT_REPORT_SUCCESS = 'GET_ASSESSMENT_REPORT_SUCCESS';
export const GET_ASSESSMENT_REPORT_FAILURE = 'GET_ASSESSMENT_REPORT_FAILURE';

export const GET_ASSESSMENT_REPORT_ID_REQUEST = 'GET_ASSESSMENT_REPORT_ID_REQUEST';
export const GET_ASSESSMENT_REPORT_ID_SUCCESS = 'GET_ASSESSMENT_REPORT_ID_SUCCESS';
export const GET_ASSESSMENT_REPORT_ID_FAILURE = 'GET_ASSESSMENT_REPORT_ID_FAILURE';

export const GET_ASSESSMENT_REPORT_STUDENT_REQUEST = 'GET_ASSESSMENT_REPORT_STUDENT_REQUEST';
export const GET_ASSESSMENT_REPORT_STUDENT_SUCCESS = 'GET_ASSESSMENT_REPORT_STUDENT_SUCCESS';
export const GET_ASSESSMENT_REPORT_STUDENT_FAILURE = 'GET_ASSESSMENT_REPORT_STUDENT_FAILURE';

export const GET_ASSESSMENT_REPORT_STUDENTID_REQUEST = 'GET_ASSESSMENT_REPORT_STUDENTID_REQUEST';
export const GET_ASSESSMENT_REPORT_STUDENTID_SUCCESS = 'GET_ASSESSMENT_REPORT_STUDENTID_SUCCESS';
export const GET_ASSESSMENT_REPORT_STUDENTID_FAILURE = 'GET_ASSESSMENT_REPORT_STUDENTID_FAILURE';

export const GET_ASSESSMENT_REPORT_GENERATE_REQUEST = 'GET_ASSESSMENT_REPORT_GENERATE_REQUEST';
export const GET_ASSESSMENT_REPORT_GENERATE_SUCCESS = 'GET_ASSESSMENT_REPORT_GENERATE_SUCCESS';
export const GET_ASSESSMENT_REPORT_GENERATE_FAILURE = 'GET_ASSESSMENT_REPORT_GENERATE_FAILURE';

export const GET_STUDENT_REPORT_GENERATE_REQUEST = 'GET_STUDENT_REPORT_GENERATE_REQUEST';
export const GET_STUDENT_REPORT_GENERATE_SUCCESS = 'GET_STUDENT_REPORT_GENERATE_SUCCESS';
export const GET_STUDENT_REPORT_GENERATE_FAILURE = 'GET_STUDENT_REPORT_GENERATE_FAILURE';
