import * as actionTypes from './constants';

const initialState = {
    getSubjectsResponse: {},
    getTopicsResponse: {},
    getResourcesResponse: {},
    getResourceByIdResponse: {},
    getQuestionsResponse: {},
    createSubjectResponse: {},
    createTopicResponse: {},
    editTopicResponse: {},
    deleteTopicResponse: {},
    deleteResourceResponse: {},
    assignSubjectsResponse: {},
    deleteSubjectResponse: {},
    editSubjectResponse: {},
    deleteQuestionResponse: {},
    getPracticeVideosResponse: {},
    getPracticeVideosByPageResponse: {},
    uploadVideoResponse: {},
    editVideoResponse: {},
    deleteVideoResponse: {},
    getGlobalResourceResponse: {},
    postAddHomeResponse: {},
    uploadThumbResponse: {},
    getClassAndDivisionByTeacherIdResponse: {},
};

const resourceManagementReducers = (state = initialState, action) => {
    switch (action.type) {
        // GET
        case actionTypes.GET_SUBJECTS_SUCCESS:
            return {
                ...state,
                getSubjectsResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_SUBJECTS_FAILURE:
            return {
                ...state,
                getSubjectsResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_TOPICS_SUCCESS:
            return {
                ...state,
                getTopicsResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_TOPICS_FAILURE:
            return {
                ...state,
                getTopicsResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_RESOURCE_SUCCESS:
            return {
                ...state,
                getResourcesResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_RESOURCE_FAILURE:
            return {
                ...state,
                getResourcesResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_RESOURCE_BY_ID_SUCCESS:
            return {
                ...state,
                getResourceByIdResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_RESOURCE_BY_ID_FAILURE:
            return {
                ...state,
                getResourceByIdResponse: { success: false, ...action.payload?.data },
            };

        case actionTypes.GET_CLASS_DIVISION_BY_TEACHER_ID_REQUEST:
            return {
                ...state,
                getClassAndDivisionByTeacherIdResponse: { success: false, ...action.payload?.data },
            };

        case actionTypes.GET_CLASS_DIVISION_BY_TEACHER_ID_SUCCESS:
            return {
                ...state,
                getClassAndDivisionByTeacherIdResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_CLASS_DIVISION_BY_TEACHER_ID_FAILURE:
            return {
                ...state,
                getClassAndDivisionByTeacherIdResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_QUESTION_SUCCESS:
            return {
                ...state,
                getQuestionsResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_QUESTION_FAILURE:
            return {
                ...state,
                getQuestionsResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_QUESTION_RESET:
            return {
                ...state,
                getQuestionsResponse: {},
            };
        // POST
        case actionTypes.POST_SUBJECTS_SUCCESS:
            return {
                ...state,
                createSubjectResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_SUBJECTS_FAILURE:
            return {
                ...state,
                createSubjectResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_SUBJECTS_RESET:
            return {
                ...state,
                createSubjectResponse: {},
            };
        case actionTypes.POST_TOPICS_SUCCESS:
            return {
                ...state,
                createTopicResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_TOPICS_FAILURE:
            return {
                ...state,
                createTopicResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.POST_TOPICS_RESET:
            return {
                ...state,
                createTopicResponse: {},
            };
        case actionTypes.POST_ASSIGNSUBJECTS_SUCCESS:
            return {
                ...state,
                assignSubjectsResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_ASSIGNSUBJECTS_FAILURE:
            return {
                ...state,
                assignSubjectsResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.POST_ASSIGNSUBJECTS_RESET:
            return {
                ...state,
                assignSubjectsResponse: {},
            };
        case actionTypes.POST_VIDEO_SUCCESS:
            return {
                ...state,
                uploadVideoResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_VIDEO_FAILURE:
            return {
                ...state,
                uploadVideoResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.POST_THUMB_SUCCESS:
            return {
                ...state,
                uploadThumbResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_THUMB_FAILURE:
            return {
                ...state,
                uploadThumbResponse: { success: false, ...action.payload?.data },
            };
        // PUT
        case actionTypes.PUT_TOPICS_SUCCESS:
            return {
                ...state,
                editTopicResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PUT_TOPICS_FAILURE:
            return {
                ...state,
                editTopicResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.PUT_TOPICS_RESET:
            return {
                ...state,
                editTopicResponse: {},
            };
        case actionTypes.PUT_VIDEO_SUCCESS:
            return {
                ...state,
                editVideoResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PUT_VIDEO_FAILURE:
            return {
                ...state,
                editVideoResponse: { success: false, ...action.payload?.data },
            };
        // DELETE
        case actionTypes.DELETE_TOPICS_SUCCESS:
            return {
                ...state,
                deleteTopicResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.DELETE_TOPICS_FAILURE:
            return {
                ...state,
                deleteTopicResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.DELETE_TOPICS_RESET:
            return {
                ...state,
                deleteTopicResponse: {},
            };
        case actionTypes.DELETE_RESOURCE_SUCCESS:
            return {
                ...state,
                deleteResourceResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.DELETE_RESOURCE_FAILURE:
            return {
                ...state,
                deleteResourceResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.DELETE_RESOURCE_RESET:
            return {
                ...state,
                deleteResourceResponse: {},
            };
        case actionTypes.DELETE_QUESTION_SUCCESS:
            return {
                ...state,
                deleteQuestionResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.DELETE_QUESTION_FAILURE:
            return {
                ...state,
                deleteQuestionResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.DELETE_QUESTION_RESET:
            return {
                ...state,
                deleteQuestionResponse: {},
            };
        case actionTypes.DELETE_SUBJECTS_SUCCESS:
            return {
                ...state,
                deleteSubjectResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.DELETE_SUBJECTS_FAILURE:
            return {
                ...state,
                deleteSubjectResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.EDIT_SUBJECTS_SUCCESS:
            return {
                ...state,
                editSubjectResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.EDIT_SUBJECTS_FAILURE:
            return {
                ...state,
                editSubjectResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.EDIT_SUBJECTS_RESET:
            return {
                ...state,
                editSubjectResponse: {},
            };
        case actionTypes.GET_PRACTICE_VIDEO_SUCCESS:
            return {
                ...state,
                getPracticeVideosResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.GET_PRACTICE_VIDEO_FAILURE:
            return {
                ...state,
                getPracticeVideosResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_PRACTICE_VIDEO_BY_PAGE_SUCCESS:
            return {
                ...state,
                getPracticeVideosByPageResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_PRACTICE_VIDEO_BY_PAGE_FAILURE:
            return {
                ...state,
                getPracticeVideosByPageResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.DELETE_VIDEO_SUCCESS:
            return {
                ...state,
                deleteVideoResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.DELETE_VIDEO_FAILURE:
            return {
                ...state,
                deleteVideoResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.DELETE_VIDEO_RESET:
            return {
                ...state,
                deleteVideoResponse: {},
            };
        case actionTypes.GET_GLOBALRESOURCE_SUCCESS:
            return {
                ...state,
                getGlobalResourceResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.GET_GLOBALRESOURCE_FAILURE:
            return {
                ...state,
                getGlobalResourceResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.POST_ADDHOME_SUCCESS:
            return {
                ...state,
                postAddHomeResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_ADDHOME_FAILURE:
            return {
                ...state,
                postAddHomeResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        default:
            return state;
    }
};

export default resourceManagementReducers;
