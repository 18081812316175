import React from 'react';
import './loader.css';

const Loader = (props) => {
    // eslint-disable-next-line react/prop-types
    const currentLoginData = JSON.parse(sessionStorage.getItem('login-Data'));
    const profile_name = currentLoginData?.data?.userDetails?.role?.profile_name;
    const { percentage } = props;
    const animatedLoader = profile_name === 'STUDENT';
    const loaderIndex = Math.floor(Math.random() * 6);

    return (
        <div className="loader-wrap d-flex align-items-center justify-content-center">
            {percentage ? (
                <div
                    id="page-wrap"
                    style={{
                        width: '100%',
                        height: '100vh',
                        // background: "#a0959538",
                        position: 'absolute',
                        zIndex: 999999999,
                        display: 'flex',
                        justifyContent: 'center',
                        textAlign: 'center',
                        alignItems: 'center',
                    }}
                >
                    {animatedLoader ? (
                        <img src="/images/animation/progressbar.gif" className="animated-progressbar" alt="" />
                    ) : (
                        <div className="loader-area">
                            <div className="progess-area">
                                <div
                                    className="progress-bar"
                                    style={{
                                        width: percentage,
                                        height: '20px',
                                        background: '#3eab41',
                                    }}
                                />

                                <div className="progress-value">{percentage}</div>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <>
                    {animatedLoader ? (
                        <div className="lds-ripple">
                            <img
                                src={`/images/animation/loader-${loaderIndex + 1}.gif`}
                                className="animated-loader"
                                alt=""
                            />
                        </div>
                    ) : (
                        <div className="lds-ripple">
                            <div />
                            <div />
                            <div />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Loader;
