import * as actionTypes from './constants';

const initialState = {
    getAssessmentTypeResponse: {},
    createAssessmentTypeResponse: {},
    updateAssessmentTypeResponse: {},
    deleteAssessmentTypeResponse: {},
    getAssessmentResponse: {},
    getAssessmentIdResponse: {},
    createAssessmentResponse: {},
    updateAssessmentResponse: {},
    deleteAssessmentResponse: {},
    updateAssessmentStatusResponse: {},
    updateAssessmentPracticeResponse: {},
    getAssessmentGradeResponse: {},
    getAssessmentReportResponse: {},
    getAssessmentReportIdResponse: {},
    getAssessmentReportStudentResponse: {},
    getAssessmentReportStudentIdResponse: {},
    generateAssessmentResponse: {},
    generateStudentResponse: {},
};

const assessmentReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSESSMENT_TYPE_SUCCESS:
            return {
                ...state,
                getAssessmentTypeResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_ASSESSMENT_TYPE_FAILURE:
            return {
                ...state,
                getAssessmentTypeResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.CREATE_ASSESSMENT_TYPE_SUCCESS:
            return {
                ...state,
                createAssessmentTypeResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.CREATE_ASSESSMENT_TYPE_FAILURE:
            return {
                ...state,
                createAssessmentTypeResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.UPDATE_ASSESSMENT_TYPE_SUCCESS:
            return {
                ...state,
                updateAssessmentTypeResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.UPDATE_ASSESSMENT_TYPE_FAILURE:
            return {
                ...state,
                updateAssessmentTypeResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.DELETE_ASSESSMENT_TYPE_SUCCESS:
            return {
                ...state,
                deleteAssessmentTypeResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.DELETE_ASSESSMENT_TYPE_FAILURE:
            return {
                ...state,
                deleteAssessmentTypeResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_SUCCESS:
            return {
                ...state,
                getAssessmentResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_ASSESSMENT_FAILURE:
            return {
                ...state,
                getAssessmentResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_ASSESSMENT_ID_SUCCESS:
            return {
                ...state,
                getAssessmentIdResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_ASSESSMENT_ID_FAILURE:
            return {
                ...state,
                getAssessmentIdResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.CREATE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                createAssessmentResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.CREATE_ASSESSMENT_FAILURE:
            return {
                ...state,
                createAssessmentResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.UPDATE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                updateAssessmentResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.UPDATE_ASSESSMENT_FAILURE:
            return {
                ...state,
                updateAssessmentResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.DELETE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                deleteAssessmentResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.DELETE_ASSESSMENT_FAILURE:
            return {
                ...state,
                deleteAssessmentResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.UPDATE_STATUS_ASSESSMENT_SUCCESS:
            return {
                ...state,
                updateAssessmentStatusResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.UPDATE_STATUS_ASSESSMENT_FAILURE:
            return {
                ...state,
                updateAssessmentStatusResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.UPDATE_PRACTICE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                updateAssessmentPracticeResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.UPDATE_PRACTICE_ASSESSMENT_FAILURE:
            return {
                ...state,
                updateAssessmentPracticeResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_GRADE_SUCCESS:
            return {
                ...state,
                getAssessmentGradeResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_GRADE_FAILURE:
            return {
                ...state,
                getAssessmentGradeResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_SUCCESS:
            return {
                ...state,
                getAssessmentReportResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_FAILURE:
            return {
                ...state,
                getAssessmentReportResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_ID_SUCCESS:
            return {
                ...state,
                getAssessmentReportIdResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_ID_FAILURE:
            return {
                ...state,
                getAssessmentReportIdResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_STUDENT_SUCCESS:
            return {
                ...state,
                getAssessmentReportStudentResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_STUDENT_FAILURE:
            return {
                ...state,
                getAssessmentReportStudentResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_STUDENTID_SUCCESS:
            return {
                ...state,
                getAssessmentReportStudentIdResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_STUDENTID_FAILURE:
            return {
                ...state,
                getAssessmentReportStudentIdResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_GENERATE_SUCCESS:
            return {
                ...state,
                generateAssessmentResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_ASSESSMENT_REPORT_GENERATE_FAILURE:
            return {
                ...state,
                generateAssessmentResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_STUDENT_REPORT_GENERATE_SUCCESS:
            return {
                ...state,
                generateStudentResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.GET_STUDENT_REPORT_GENERATE_FAILURE:
            return {
                ...state,
                generateStudentResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        default:
            return state;
    }
};

export default assessmentReducers;
