import * as actionTypes from './constants';

const initialState = {
    getPracticeUrlResponse: {},
    getPracticeAssessmentResponse: {},
    getPracticeSubmitResponse: {},
};

const practiceUrlReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PARCTICE_URL_SUCCESS:
            return {
                ...state,
                getPracticeUrlResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_PARCTICE_URL_FAILURE:
            return {
                ...state,
                getPracticeUrlResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_PARCTICE_ASSESSMENT_SUCCESS:
            return {
                ...state,
                getPracticeAssessmentResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_PARCTICE_ASSESSMENT_FAILURE:
            return {
                ...state,
                getPracticeAssessmentResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_PARCTICE_SUBMIT_SUCCESS:
            return {
                ...state,
                getPracticeSubmitResponse: { success: true },
            };
        case actionTypes.GET_PARCTICE_SUBMIT_FAILURE:
            return {
                ...state,
                getPracticeSubmitResponse: { success: false },
            };
        default:
            return state;
    }
};

export default practiceUrlReducers;
