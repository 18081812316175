import * as commonTypes from '../common/constants';
import * as service from './services';
import { request } from '../../utils/utils';

// *Attendance action-types
const GET_ATTENDANCE_REQUEST = 'GET_ATTENDANCE_REQUEST';
const GET_ATTENDANCE_SUCCESS = 'GET_ATTENDANCE_SUCCESS';
const GET_ATTENDANCE_FAILURE = 'GET_ATTENDANCE_FAILURE';

const GET_TEACHER_CLASS_DIVISIONS_REQUEST = 'GET_TEACHER_CLASS_DIVISIONS_REQUEST';
const GET_TEACHER_CLASS_DIVISIONS_SUCCESS = 'GET_TEACHER_CLASS_DIVISIONS_SUCCESS';
const GET_TEACHER_CLASS_DIVISIONS_FAILURE = 'GET_TEACHER_CLASS_DIVISIONS_FAILURE';

// *Attendance actions
export const getAttendance = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getAttendance,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_ATTENDANCE_REQUEST }],
        successActions: [
            { type: GET_ATTENDANCE_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_ATTENDANCE_REQUEST },
        ],
        failureActions: [
            { type: GET_ATTENDANCE_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_ATTENDANCE_REQUEST },
        ],
    });
};

export const getClassDivisionsByTeacherId = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getClassDivisionsByTeacherId,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: GET_TEACHER_CLASS_DIVISIONS_REQUEST }],
        successActions: [
            { type: GET_TEACHER_CLASS_DIVISIONS_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_TEACHER_CLASS_DIVISIONS_REQUEST },
        ],
        failureActions: [
            { type: GET_TEACHER_CLASS_DIVISIONS_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: GET_TEACHER_CLASS_DIVISIONS_REQUEST },
        ],
    });
};

// *Attendance reducer
const initialState = {
    getAttendanceListRes: {},
    getclassDivisionOfTeacherRes: {},
};

const AttendanceReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ATTENDANCE_SUCCESS:
            return {
                ...state,
                getAttendanceListRes: { success: true, ...action.payload?.data },
            };
        case GET_ATTENDANCE_FAILURE:
            return {
                ...state,
                getAttendanceListRes: { success: false, ...action.payload?.data },
            };
        case GET_TEACHER_CLASS_DIVISIONS_SUCCESS:
            return {
                ...state,
                getclassDivisionOfTeacherRes: { success: true, ...action.payload?.data },
            };
        case GET_TEACHER_CLASS_DIVISIONS_FAILURE:
            return {
                ...state,
                getclassDivisionOfTeacherRes: { success: false, ...action.payload?.data },
            };
        default:
            return state;
    }
};

export default AttendanceReducer;
