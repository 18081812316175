import React from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import App from './App';
import configureStore from './store';

// creating the redux store
const store = configureStore();

const ErrorFallback = ({ error, ...rest }) => {
    // https://kentcdodds.com/blog/use-react-error-boundary-to-handle-errors-in-react
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            <pre style={{ color: 'red' }}>{error.message}</pre>
        </div>
    );
};

ReactDOM.render(
    <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Provider store={store}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
