/* eslint-disable no-restricted-syntax */
const menu = {
    DASHBOARD: {
        redirectUrl: '/',
        imageUrl: '/images/icons/dashboard.svg',
    },
    ACCESS_MANAGEMENT: {
        redirectUrl: '/access',
        imageUrl: '/images/icons/access.svg',
    },
    SCHOOL_MANAGEMENT: {
        redirectUrl: '/school/list',
        imageUrl: '/images/icons/school.svg',
    },
    TEACHER_MANAGEMENT: {
        redirectUrl: '/teacher',
        imageUrl: '/images/icons/teacher.svg',
    },
    STUDENT_MANAGEMENT: {
        redirectUrl: '/student',
        imageUrl: '/images/icons/student.svg',
    },
    RESOURCE_MANAGEMENT: {
        redirectUrl: '/resource/all-resources',
        imageUrl: '/images/icons/resource.svg',
    },
    USER_MANAGEMENT: {
        redirectUrl: '/users/list/admin',
        imageUrl: '/images/icons/user.svg',
    },
    ASSIGN_CLASS: {
        redirectUrl: '/assign-class',
        imageUrl: '/images/icons/assign-class.svg',
    },
    COURSE_PLANNER: {
        redirectUrl: '/course-planer',
        imageUrl: '/images/icons/course.svg',
    },
    ASSESSMENT: {
        redirectUrl: '/assessment',
        imageUrl: '/images/icons/assessment.svg',
    },
    COMMUNICATE: {
        redirectUrl: '/communicate',
        imageUrl: '/images/icons/communicate.svg',
    },
    TIMETABLE: {
        redirectUrl: '/timetable/create',
        imageUrl: '/images/icons/timetable.svg',
    },
    LEAVES: {
        redirectUrl: '/leaves',
        imageUrl: '/images/icons/timetable.svg',
    },
    MEETING: {
        redirectUrl: '/meeting',
        imageUrl: '/images/icons/meeting.svg',
    },
    GENERAL_SETTINGS: {
        redirectUrl: '/settings',
        imageUrl: '/images/icons/settings.svg',
    },
    CLASS_ROOM: {
        redirectUrl: '/class-room',
        imageUrl: '/images/icons/course.svg',
    },
    PRACTICE: {
        redirectUrl: '/practices',
        imageUrl: '/images/icons/resource.svg',
    },
    STUDENT_ATTENDANCE: {
        redirectUrl: `/attendance/list`,
        imageUrl: '/images/icons/student.svg',
    },
};

const usermngRedirect = (val) => {
    let url;
    switch (val) {
        case 'SCHOOL_USERS':
            url = 'school';
            break;
        case 'BRANCH_USERS':
            url = 'branch';
            break;

        default:
            url = 'admin';
            break;
    }
    return url;
};

const redirectFn = (name, permission) => {
    const {
        userDetails: {
            id,
            school_id,
            branch_id,
            role: { profile_name },
        },
    } = JSON.parse(sessionStorage.getItem('login-Data')).data;
    let temp = { ...menu };
    const perm = permission?.map((item) => item?.permission_name);

    switch (profile_name) {
        case 'SCHOOL':
            temp = {
                ...temp,
                SCHOOL_MANAGEMENT: {
                    redirectUrl: `/school/view/${school_id}/basic-info`,
                    imageUrl: '/images/icons/school.svg',
                },
                USER_MANAGEMENT: {
                    redirectUrl: perm?.length ? `/users/list/${usermngRedirect(perm[0])}` : '/users/list/admin',
                    imageUrl: '/images/icons/user.svg',
                },
                STUDENT_ATTENDANCE: {
                    redirectUrl: `/attendance/list`,
                    imageUrl: '/images/icons/student.svg',
                },
            };
            break;
        case 'BRANCH':
            temp = {
                ...temp,
                SCHOOL_MANAGEMENT: {
                    redirectUrl: `/school/view/${school_id}/branch/view/${branch_id}/basic-info`,
                    imageUrl: '/images/icons/school.svg',
                },
                USER_MANAGEMENT: {
                    redirectUrl: perm?.length ? `/users/list/${usermngRedirect(perm[0])}` : '/users/list/admin',
                    imageUrl: '/images/icons/user.svg',
                },
                STUDENT_ATTENDANCE: {
                    redirectUrl: `/attendance/list`,
                    imageUrl: '/images/icons/student.svg',
                },
            };
            break;

        case 'TEACHER':
            temp = {
                ...temp,
                TEACHER_MANAGEMENT: {
                    redirectUrl: `/teacher/view/${id}/basic-info`,
                    imageUrl: '/images/icons/teacher.svg',
                },
                STUDENT_ATTENDANCE: {
                    redirectUrl: `/attendance/list`,
                    imageUrl: '/images/icons/student.svg',
                },
            };
            break;

        case 'STUDENT':
            temp = {
                ...temp,
                STUDENT_MANAGEMENT: {
                    redirectUrl: `/student/view/${id}/basic-info`,
                    imageUrl: '/images/icons/student.svg',
                },
            };
            break;

        default:
            temp = { ...menu };
            break;
    }
    return temp[name];
};

export default redirectFn;

export const transformMenus = (menus) => {
    const {
        userDetails: {
            role: { profile_name },
        },
    } = JSON.parse(sessionStorage.getItem('login-Data')).data;

    let sideBarMenus = [];
    const mainMenus = {
        display_name: 'Main Menu',
        is_dropable: true,
        imageUrl: '/images/icons/access.svg',
        sub_menus: [],
    };
    const resources = {
        display_name: 'Lessons',
        is_dropable: true,
        imageUrl: '/images/icons/resource.svg',
        sub_menus: [],
    };
    const settings = {
        display_name: 'General Settings',
        is_dropable: false,
        imageUrl: '/images/icons/settings.svg',
        sub_menus: [],
    };
    for (const item of menus) {
        if (
            item.permission_name === 'DASHBOARD' ||
            item.permission_name === 'ASSESSMENT' ||
            item.permission_name === 'COMMUNICATE' ||
            item.permission_name === 'CLASS_ROOM' ||
            item.permission_name === 'STUDENT_ATTENDANCE'
        ) {
            sideBarMenus.push({
                ...item,
                is_dropable: false,
            });
        } else if (
            item.permission_name === 'ACCESS_MANAGEMENT' ||
            item.permission_name === 'SCHOOL_MANAGEMENT' ||
            item.permission_name === 'TEACHER_MANAGEMENT' ||
            item.permission_name === 'STUDENT_MANAGEMENT' ||
            item.permission_name === 'USER_MANAGEMENT'
        ) {
            mainMenus.sub_menus.push(item);
        } else if (
            item.permission_name === 'RESOURCE_MANAGEMENT' ||
            item.permission_name === 'COURSE_PLANNER' ||
            item.permission_name === 'ASSIGN_CLASS' ||
            item.permission_name === 'TIMETABLE' ||
            item.permission_name === 'PRACTICE'
        ) {
            resources.sub_menus.push(item);
        } else if (item.permission_name === 'GENERAL_SETTINGS' || item.permission_name === 'LEAVES') {
            settings.sub_menus.push(item);
        }
    }
    if (mainMenus.sub_menus.length) sideBarMenus = [...sideBarMenus, { ...mainMenus }];
    if (resources.sub_menus.length) sideBarMenus = [...sideBarMenus, { ...resources }];
    if (settings.sub_menus.length) sideBarMenus = [...sideBarMenus, { ...settings }];

    const removeSettings = sideBarMenus.filter((item) => item?.display_name === 'General Settings').pop();

    sideBarMenus = sideBarMenus
        .filter((item) => item?.display_name !== 'General Settings')
        .map((item) => {
            if (item?.display_name === 'Main Menu') {
                return {
                    ...item,
                    sub_menus: [...item.sub_menus, { ...removeSettings, permission_name: 'GENERAL_SETTINGS' }],
                };
            }
            return item;
        });

    if (profile_name === 'TEACHER') {
        const assessitem = sideBarMenus.filter((item) => item?.permission_name === 'ASSESSMENT').pop();
        const reorderitem = sideBarMenus.filter((item) => item?.permission_name === 'CLASS_ROOM').pop();
        const reorderitem1 = sideBarMenus.filter((item) => item?.display_name === 'Lessons').pop();

        if (sideBarMenus.length) {
            sideBarMenus = sideBarMenus.filter((item) => item?.permission_name !== 'CLASS_ROOM');
            sideBarMenus.splice(5, 0, reorderitem);
        }

        if (sideBarMenus.length) {
            sideBarMenus = sideBarMenus.filter((item) => item?.display_name !== 'Lessons');
            sideBarMenus.splice(2, 0, reorderitem1);
        }

        if (assessitem?.is_active) {
            sideBarMenus = sideBarMenus
                .filter((item) => item?.permission_name !== 'ASSESSMENT')
                .map((item) => {
                    if (item?.display_name === 'Lessons') {
                        return { ...item, sub_menus: [...item.sub_menus, assessitem] };
                    }
                    return item;
                });
        }
        sideBarMenus = sideBarMenus.filter((item) => item?.display_name !== 'Class Room');
        sideBarMenus = sideBarMenus.map((item) => {
            if (item?.display_name === 'Main Menu') {
                return {
                    ...item,
                    sub_menus: item?.sub_menus.filter((x) => x?.permission_name !== 'GENERAL_SETTINGS'),
                };
            }
            return item;
        });
    }

    if (profile_name === 'STUDENT') {
        const assessitem1 = sideBarMenus.filter((item) => item?.permission_name === 'CLASS_ROOM').pop();

        if (assessitem1?.is_active) {
            sideBarMenus = sideBarMenus
                .filter((item) => item?.permission_name !== 'CLASS_ROOM')
                .map((item) => {
                    if (item?.display_name === 'Main Menu') {
                        return { ...item, sub_menus: [...item.sub_menus, assessitem1] };
                    }
                    return item;
                });
        }
        sideBarMenus = sideBarMenus.map((item) => {
            if (item?.display_name === 'Main Menu') {
                return {
                    ...item,
                    sub_menus: item?.sub_menus.filter(
                        (x) => x?.permission_name !== 'CLASS_ROOM' && x?.permission_name !== 'GENERAL_SETTINGS'
                    ),
                };
            }
            return item;
        });
    }

    if (profile_name !== 'ADMIN') {
        sideBarMenus = sideBarMenus.map((item) => {
            if (item?.display_name === 'Lessons') {
                return {
                    ...item,
                    sub_menus: item?.sub_menus.filter(
                        (x) => x?.permission_name !== 'COURSE_PLANNER' && x?.permission_name !== 'TIMETABLE'
                    ),
                };
            }
            return item;
        });
    }
    return sideBarMenus;
};
