import * as actionType from './constants';

const initialState = {
    // loaderList: [],
    loader: false,
    sideBarResponse: {},
    toggleFilter: { isVisible: false },
    toggleSidebar: { isExpanded: true },
    getBreadCrumbDataResponse: {},
};

const commonReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_LOADER:
            return {
                ...state,
                // loaderList: [...state.loaderList, action.payload],
                loader: true,
            };
        case actionType.REMOVE_LOADER:
            return {
                ...state,
                // loaderList: state.loaderList.filter((x) => x !== action.payload),
                loader: false,
            };
        case actionType.UPDATE_SIDEBAR_SUCCESS:
            return {
                ...state,
                sideBarResponse: { success: true, ...action.payload },
            };
        case actionType.UPDATE_SIDEBAR_FAILURE:
            return {
                ...state,
                sideBarResponse: { success: false, ...action.payload },
            };
        case actionType.TOGGLE_FILTER:
            return {
                ...state,
                toggleFilter: { isVisible: !state.toggleFilter.isVisible },
            };
        case actionType.TOGGLE_SIDEBAR:
            return {
                ...state,
                toggleSidebar: { isExpanded: !state.toggleSidebar.isExpanded },
            };
        case actionType.TOGGLE_SIDEBAR_ON:
            return {
                ...state,
                toggleSidebar: { isExpanded: true },
            };
        case actionType.TOGGLE_SIDEBAR_OFF:
            return {
                ...state,
                toggleSidebar: { isExpanded: false },
            };

        case actionType.GET_BREADCRUMB_DATA_SUCCESS:
            return {
                ...state,
                getBreadCrumbDataResponse: { success: true, ...action.payload.data.data },
            };
        case actionType.GET_BREADCRUMB_DATA_FAILURE:
            return {
                ...state,
                getBreadCrumbDataResponse: { success: false, ...action.payload },
            };
        default:
            return state;
    }
};

export default commonReducers;
