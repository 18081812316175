import * as actionType from './constants';

const initialState = {
    getRoleProfilesResponse: {},
    getPermissionsResponse: {},
    createRolePermissionsResponse: {},
    roleList: {},
    permissionsById: {},
    deleteRoleResponse: {},
    validateRoleResponse: {},
};

const accessReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionType.GET_ROLE_PROFILES_SUCCESS:
            return {
                ...state,
                getRoleProfilesResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionType.GET_ROLE_PROFILES_FAILURE:
            return {
                ...state,
                getRoleProfilesResponse: { success: false, ...action.payload?.data?.data },
            };

        case actionType.GET_PERMISSIONS_SUCCESS:
            return {
                ...state,
                getPermissionsResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionType.GET_PERMISSIONS_FAILURE:
            return {
                ...state,
                getPermissionsResponse: { success: false, ...action.payload?.data?.data },
            };

        case actionType.CREATE_ROLE_PERMISSION_SUCCESS:
            return {
                ...state,
                createRolePermissionsResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionType.CREATE_ROLE_PERMISSION_FAILURE:
            return {
                ...state,
                createRolePermissionsResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };

        case actionType.SEND_GETROLES_SUCCESS:
            return {
                ...state,
                roleList: { success: true, ...action.payload?.data },
            };
        case actionType.SEND_GETROLES_FAILURE:
            return {
                ...state,
                roleList: { success: false, ...action.payload?.data },
            };
        case actionType.SEND_GETROLES_RESET:
            return {
                ...state,
                roleList: {},
            };
        case actionType.GET_ROLEPERMISSION_SUCCESS:
            return {
                ...state,
                permissionsById: { success: true, ...action.payload },
            };
        case actionType.GET_ROLEPERMISSION_FAILURE:
            return {
                ...state,
                permissionsById: { success: false, ...action.payload },
            };
        case actionType.GET_ROLEPERMISSION_RESET:
            return {
                ...state,
                permissionsById: {},
            };
        case actionType.UPDATE_ROLE_PERMISSION_SUCCESS:
            return {
                ...state,
                updateRolePermissionsResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionType.UPDATE_ROLE_PERMISSION_FAILURE:
            return {
                ...state,
                updateRolePermissionsResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };

        case actionType.DELETE_ROLE_PERMISSION_SUCCESS:
            return {
                ...state,
                deleteRoleResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionType.DELETE_ROLE_PERMISSION_FAILURE:
            return {
                ...state,
                deleteRoleResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionType.VALIDATE_ROLE_SUCCESS:
            return {
                ...state,
                validateRoleResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionType.VALIDATE_ROLE_FAILURE:
            return {
                ...state,
                validateRoleResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        default:
            return state;
    }
};

export default accessReducers;
