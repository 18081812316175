import * as actionTypes from './constants';

const initialState = {
    getStudentListResponse: {},
    getStudentIdResponse: {},
    postStudentCreateResponse: {},
    editStudentCreateResponse: {},
    getClassListResponse: {},
    getDivListResponse: {},
    getAttendanceResponse: {},
    studentBulkUploadResponse: {},
    getRemarkResponse: {},
    postRemarkResponse: {},
    editRemarksResponse: {},
    deleteRemarkResponse: {},
};

const studentManagementReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_STUDENT_LIST_SUCCESS:
            return {
                ...state,
                getStudentListResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_STUDENT_LIST_FAILURE:
            return {
                ...state,
                getStudentListResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.GET_CLASS_LIST_SUCCESS:
            return {
                ...state,
                getClassListResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_CLASS_LIST_FAILURE:
            return {
                ...state,
                getClassListResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.GET_DIVISION_LIST_SUCCESS:
            return {
                ...state,
                getDivListResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_DIVISION_LIST_FAILURE:
            return {
                ...state,
                getDivListResponse: { success: false, ...action.payload?.data?.data },
            };

        case actionTypes.GET_STUDENT_ATTENDANCE_SUCCESS:
            return {
                ...state,
                getAttendanceResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_STUDENT_ATTENDANCE_FAILURE:
            return {
                ...state,
                getAttendanceResponse: { success: false, ...action.payload?.data?.data },
            };

        case actionTypes.GET_STUDENT_ID_SUCCESS:
            return {
                ...state,
                getStudentIdResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_STUDENT_ID_FAILURE:
            return {
                ...state,
                getStudentListResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.GET_REMARK_SUCCESS:
            return {
                ...state,
                getRemarkResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_REMARK_FAILURE:
            return {
                ...state,
                getRemarkResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.CREATE_STUDENT_SUCCESS:
            return {
                ...state,
                postStudentCreateResponse: { success: true, message: action.payload.message, ...action.payload?.data },
            };
        case actionTypes.CREATE_STUDENT_FAILURE:
            return {
                ...state,
                postStudentCreateResponse: {
                    success: false,
                    message: action.payload.message,
                    ...action.payload?.data?.data,
                },
            };
        case actionTypes.CREATE_STUDENT_RESET:
            return {
                ...state,
                postStudentCreateResponse: {},
            };
        case actionTypes.CREATE_REMARK_SUCCESS:
            return {
                ...state,
                postRemarkResponse: { success: true, message: action.payload.message, ...action.payload?.data },
            };
        case actionTypes.CREATE_REMARK_FAILURE:
            return {
                ...state,
                postRemarkResponse: {
                    success: false,
                    message: action.payload.message,
                    ...action.payload?.data?.data,
                },
            };
        case actionTypes.CREATE_REMARK_RESET:
            return {
                ...state,
                postRemarkResponse: {},
            };
        case actionTypes.EDIT_STUDENT_SUCCESS:
            return {
                ...state,
                editStudentCreateResponse: { success: true, message: action.payload.message, ...action.payload?.data },
            };
        case actionTypes.EDIT_STUDENT_FAILURE:
            return {
                ...state,
                editStudentCreateResponse: {
                    success: false,
                    message: action.payload.message,
                    ...action.payload?.data?.data,
                },
            };
        case actionTypes.EDIT_STUDENT_RESET:
            return {
                ...state,
                editStudentCreateResponse: {},
            };
        case actionTypes.EDIT_REMARK_SUCCESS:
            return {
                ...state,
                editRemarksResponse: { success: true, message: action.payload.message, ...action.payload?.data },
            };
        case actionTypes.EDIT_REMARK_FAILURE:
            return {
                ...state,
                editRemarksResponse: {
                    success: false,
                    message: action.payload.message,
                    ...action.payload?.data?.data,
                },
            };
        case actionTypes.EDIT_REMARK_RESET:
            return {
                ...state,
                editRemarksResponse: {},
            };
        case actionTypes.STUDENT_BULK_UPLOAD_SUCCESS:
            return {
                ...state,
                studentBulkUploadResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.STUDENT_BULK_UPLOAD_FAILURE:
            return {
                ...state,
                studentBulkUploadResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.DELETE_REMARK_SUCCESS:
            return {
                ...state,
                deleteRemarkResponse: { success: true, message: action.payload.message, ...action.payload?.data },
            };
        case actionTypes.DELETE_REMARK_FAILURE:
            return {
                ...state,
                deleteRemarkResponse: {
                    success: false,
                    message: action.payload.message,
                    ...action.payload?.data?.data,
                },
            };
        case actionTypes.DELETE_REMARK_RESET:
            return {
                ...state,
                deleteRemarkResponse: {},
            };

        default:
            return state;
    }
};

export default studentManagementReducers;
