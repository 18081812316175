import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import usePrevious from '../../utils/usePrevious';
import { camelToSnakeCase, capitalizeFirstLetter } from '../../utils/utils';
import { getBreadCrumbData } from './actions';

const BreadCrumb = () => {
    const location = useLocation();
    const { pathname } = location;
    const urlParams = useParams();
    const dispatch = useDispatch();

    const [finalBreadCrumb, setFinalBreadCrumb] = useState([]);

    const {
        userDetails: {
            role: { profile_name },
        },
    } = JSON.parse(sessionStorage.getItem('login-Data')).data;

    const getBreadCrumbDataResponse = useSelector((state) => state.commonReducers.getBreadCrumbDataResponse);
    // const oldUrlIds = usePrevious(Object.values(urlParams).map(i => !Number.isNaN(i) ? i : "").map(Number))
    // const newUrlIds = Object.values(urlParams).map(i => !Number.isNaN(i) ? i : "").map(Number)
    const oldPathName = usePrevious(pathname);

    useEffect(() => {
        const body = {};
        if (JSON.stringify(oldPathName) !== JSON.stringify(pathname)) {
            Object.keys(urlParams).forEach((item) => {
                body[camelToSnakeCase(item)] = urlParams[item];
            });
            dispatch(getBreadCrumbData(body));
        }
    }, [urlParams]);

    const getRoutePath = (path, params) => {
        if (!Object.keys(params).length) {
            return path;
        }
        let newPpath = path;
        Object.entries(params).forEach(([paramName, paramValue]) => {
            if (paramValue) {
                newPpath = newPpath.replace(paramValue, `:${paramName}`);
            }
        });
        return newPpath;
    };

    const buildBreadCrumb = (bcData) => {
        const routePath = getRoutePath(pathname, urlParams);
        const temp = routePath.slice();

        let editedPathName = temp.split('/view').join('');
        if (editedPathName === '/') {
            editedPathName = profile_name === 'STUDENT' ? 'Overview' : 'Dashboard';
        }
        editedPathName = editedPathName.replace('basic-info', 'Basic Info');

        let splittedPathName = editedPathName.split('/').filter((x) => x && !x.includes('*'));
        if (splittedPathName.length) {
            splittedPathName[0] = `${capitalizeFirstLetter(splittedPathName[0])} ${
                profile_name !== 'STUDENT' ? 'Management' : ''
            }`;
        }

        splittedPathName = splittedPathName.map((x) => capitalizeFirstLetter(x)).join('/');

        Object.keys(bcData).forEach((item) => {
            splittedPathName = splittedPathName.replace(item, bcData[item]);
        });

        // eslint-disable-next-line no-restricted-globals
        setFinalBreadCrumb(
            splittedPathName
                .split(':')
                .join('')
                .split('/')
                .filter((it) => isNaN(it))
        );
    };

    const parseBreadCrumbData = (entity, data) => {
        switch (entity) {
            case 'user':
                return `${data.first_name || ''} ${data.last_name || ''}`.trim();
            case 'school':
                return data.school_name.trim();
            case 'branch':
                return data.branch_name.trim();
            case 'teacher':
                return `${data.first_name || ''} ${data.last_name || ''}`.trim();
            default:
                return '';
        }
    };

    useEffect(() => {
        if (getBreadCrumbDataResponse?.success) {
            const { details } = getBreadCrumbDataResponse;

            const temp = {};
            Object.keys(details).forEach((key) => {
                temp[`${key}Id`] = parseBreadCrumbData(key, details[key]);
            });
            buildBreadCrumb(temp);
        }
    }, [getBreadCrumbDataResponse]);

    return (
        <ol className="breadcrumb">
            {finalBreadCrumb.map((item, idx) => {
                return (
                    <li
                        key={Math.random()}
                        className={`breadcrumb-item ${idx === finalBreadCrumb.length - 1 ? 'active' : ''}`}
                    >
                        <a href="##" style={idx === finalBreadCrumb.length - 1 ? { color: '#eb0875' } : {}}>
                            {item}
                        </a>
                    </li>
                );
            })}
        </ol>
    );
};

export default BreadCrumb;
