export const GET_TEACHERS_REQUEST = 'GET_TEACHERS_REQUEST';
export const GET_TEACHERS_SUCCESS = 'GET_TEACHERS_SUCCESS';
export const GET_TEACHERS_FAILURE = 'GET_TEACHERS_FAILURE';

export const GET_TEACHER_DETAILS_REQUEST = 'GET_SCHOOl_DETAILS_REQUEST';
export const GET_TEACHER_DETAILS_SUCCESS = 'GET_SCHOOl_DETAILS_SUCCESS';
export const GET_TEACHER_DETAILS_FAILURE = 'GET_SCHOOl_DETAILS_FAILURE';

export const GET_SUBJECT_LIST_REQUEST = 'GET_SUBJECT_LIST_REQUEST';
export const GET_SUBJECT_LIST_SUCCESS = 'GET_SUBJECT_LIST_SUCCESS';
export const GET_SUBJECT_LIST_FAILURE = 'GET_SUBJECT_LIST_FAILURE';

export const GET_ASSIGNED_CLASS_REQUEST = 'GET_ASSIGNED_CLASS_REQUEST';
export const GET_ASSIGNED_CLASS_SUCCESS = 'GET_ASSIGNED_CLASS_SUCCESS';
export const GET_ASSIGNED_CLASS_FAILURE = 'GET_ASSIGNED_CLASS_FAILURE';

export const GET_BULK_UPLOAD_REQUEST = 'GET_BULK_UPLOAD_REQUEST';
export const GET_BULK_UPLOAD_SUCCESS = 'GET_BULK_UPLOAD_SUCCESS';
export const GET_BULK_UPLOAD_FAILURE = 'GET_BULK_UPLOAD_FAILURE';

export const GET_TEACHER_ATTENDENCE_REQUEST = 'GET_TEACHER_ATTENDENCE_REQUEST';
export const GET_TEACHER_ATTENDENCE_SUCCESS = 'GET_TEACHER_ATTENDENCE_SUCCESS';
export const GET_TEACHER_ATTENDENCE_FAILURE = 'GET_TEACHER_ATTENDENCE_FAILURE';

export const GET_INDIVIDUAL_COMMUNICATION_REQUEST = 'GET_INDIVIDUAL_COMMUNICATION_REQUEST';
export const GET_INDIVIDUAL_COMMUNICATION_SUCCESS = 'GET_INDIVIDUAL_COMMUNICATION_SUCCESS';
export const GET_INDIVIDUAL_COMMUNICATION_FAILURE = 'GET_INDIVIDUAL_COMMUNICATION_FAILURE';
export const GET_INDIVIDUAL_COMMUNICATION_RESET = 'GET_INDIVIDUAL_COMMUNICATION_RESET';

export const CREATE_TEACHER_REQUEST = 'CREATE_TEACHER_REQUEST';
export const CREATE_TEACHER_SUCCESS = 'CREATE_TEACHER_SUCCESS';
export const CREATE_TEACHER_FAILURE = 'CREATE_TEACHER_FAILURE';

export const CREATE_ASSIGNED_CLASS_REQUEST = 'CREATE_ASSIGNED_CLASS_REQUEST';
export const CREATE_ASSIGNED_CLASS_SUCCESS = 'CREATE_ASSIGNED_CLASS_SUCCESS';
export const CREATE_ASSIGNED_CLASS_FAILURE = 'CREATE_ASSIGNED_CLASS_FAILURE';

export const DELETE_ASSIGNED_CLASS_REQUEST = 'DELETE_ASSIGNED_CLASS_REQUEST';
export const DELETE_ASSIGNED_CLASS_SUCCESS = 'DELETE_ASSIGNED_CLASS_SUCCESS';
export const DELETE_ASSIGNED_CLASS_FAILURE = 'DELETE_ASSIGNED_CLASS_FAILURE';

export const UPDATE_ASSIGNED_CLASS_REQUEST = 'UPDATE_ASSIGNED_CLASS_REQUEST';
export const UPDATE_ASSIGNED_CLASS_SUCCESS = 'UPDATE_ASSIGNED_CLASS_SUCCESS';
export const UPDATE_ASSIGNED_CLASS_FAILURE = 'UPDATE_ASSIGNED_CLASS_FAILURE';

export const UPDATE_TEACHER_REQUEST = 'UPDATE_TEACHER_REQUEST';
export const UPDATE_TEACHER_SUCCESS = 'UPDATE_TEACHER_SUCCESS';
export const UPDATE_TEACHER_FAILURE = 'UPDATE_TEACHER_FAILURE';

export const TEACHER_BULK_UPLOAD_REQUEST = 'TEACHER_BULK_UPLOAD_REQUEST';
export const TEACHER_BULK_UPLOAD_SUCCESS = 'TEACHER_BULK_UPLOAD_SUCCESS';
export const TEACHER_BULK_UPLOAD_FAILURE = 'TEACHER_BULK_UPLOAD_FAILURE';
