import * as actionTypes from './constants';

const initialState = {
    getSessionListResponse: {},
    getSessionsResponse: {},
    getAssignedTeachersResponse: {},
    getAllResourcesResponse: {},
    getAllAssessmentResponse: {},
    createSessionPlanResponse: {},
    editSessionPlanResponse: {},
    deleteSessionPlanResponse: {},
    deleteCustomResources: {},
    hasSessionPlanResponse: {},
};

const coursePlannerReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SESSION_PLAN_LIST_SUCCESS:
            return {
                ...state,
                getSessionListResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_SESSION_PLAN_LIST_FAILURE:
            return {
                ...state,
                getSessionListResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_SESSION_PLAN_LIST_CLEAR:
            return {
                ...state,
                getSessionListResponse: {},
            };
        case actionTypes.GET_SESSIONS_SUCCESS:
            return {
                ...state,
                getSessionsResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_SESSIONS_FAILURE:
            return {
                ...state,
                getSessionsResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_ASSIGNED_TEACHERS_SUCCESS:
            return {
                ...state,
                getAssignedTeachersResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_ASSIGNED_TEACHERS_FAILURE:
            return {
                ...state,
                getAssignedTeachersResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_ASSIGNED_TEACHERS_RESET:
            return {
                ...state,
                getAssignedTeachersResponse: {},
            };
        case actionTypes.GET_ALL_RESOURCES_SUCCESS:
            return {
                ...state,
                getAllResourcesResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_ALL_RESOURCES_FAILURE:
            return {
                ...state,
                getAllResourcesResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_ALL_RESOURCES_RESET:
            return {
                ...state,
                getAllAssessmentResponse: {},
            };
        case actionTypes.GET_ALL_ASSESSMENT_SUCCESS:
            return {
                ...state,
                getAllAssessmentResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_ALL_ASSESSMENT_FAILURE:
            return {
                ...state,
                getAllAssessmentResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_ALL_ASSESSMENT_RESET:
            return {
                ...state,
                getAllResourcesResponse: {},
            };
        case actionTypes.CREATE_SESSION_PLAN_SUCCESS:
            return {
                ...state,
                createSessionPlanResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.CREATE_SESSION_PLAN_FAILURE:
            return {
                ...state,
                createSessionPlanResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.EDIT_SESSION_PLAN_SUCCESS:
            return {
                ...state,
                editSessionPlanResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.EDIT_SESSION_PLAN_FAILURE:
            return {
                ...state,
                editSessionPlanResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.DELETE_SESSION_PLAN_SUCCESS:
            return {
                ...state,
                deleteSessionPlanResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.DELETE_SESSION_PLAN_FAILURE:
            return {
                ...state,
                deleteSessionPlanResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.DELETE_CUSTOM_RESOURCE_SUCCESS:
            return {
                ...state,
                deleteCustomResources: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.DELETE_CUSTOM_RESOURCE_FAILURE:
            return {
                ...state,
                deleteCustomResources: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.UPDATE_HAS_SESSION_PLAN:
            return {
                ...state,
                hasSessionPlanResponse: {
                    ...action.payload,
                },
            };
        case actionTypes.RESET_HAS_SESSION_PLAN:
            return {
                ...state,
                hasSessionPlanResponse: {},
            };
        default:
            return state;
    }
};

export default coursePlannerReducers;
