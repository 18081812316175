export const GET_HOLIDAY_TYPE_REQUEST = 'GET_HOLIDAY_TYPE_REQUEST';
export const GET_HOLIDAY_TYPE_SUCCESS = 'GET_HOLIDAY_TYPE_SUCCESS';
export const GET_HOLIDAY_TYPE_FAILURE = 'GET_HOLIDAY_TYPE_FAILURE';

export const GET_HOLIDAY_REQUEST = 'GET_HOLIDAY_REQUEST';
export const GET_HOLIDAY_SUCCESS = 'GET_HOLIDAY_SUCCESS';
export const GET_HOLIDAY_FAILURE = 'GET_HOLIDAY_FAILURE';

export const POST_HOLIDAY_REQUEST = 'POST_HOLIDAY_REQUEST';
export const POST_HOLIDAY_SUCCESS = 'POST_HOLIDAY_SUCCESS';
export const POST_HOLIDAY_FAILURE = 'POST_HOLIDAY_FAILURE';
export const POST_HOLIDAY_RESET = 'POST_HOLIDAY_RESET';

export const PUT_HOLIDAY_REQUEST = 'PUT_HOLIDAY_REQUEST';
export const PUT_HOLIDAY_SUCCESS = 'PUT_HOLIDAY_SUCCESS';
export const PUT_HOLIDAY_FAILURE = 'PUT_HOLIDAY_FAILURE';
export const PUT_HOLIDAY_RESET = 'PUT_HOLIDAY_RESET';

export const DELETE_HOLIDAY_REQUEST = 'DELETE_HOLIDAY_REQUEST';
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_FAILURE = 'DELETE_HOLIDAY_FAILURE';
export const DELETE_HOLIDAY_RESET = 'DELETE_HOLIDAY_RESET';

export const HOLIDAY_BULK_UPLOAD_REQUEST = 'HOLIDAY_BULK_UPLOAD_REQUEST';
export const HOLIDAY_BULK_UPLOAD_SUCCESS = 'HOLIDAY_BULK_UPLOAD_SUCCESS';
export const HOLIDAY_BULK_UPLOAD_FAILURE = 'HOLIDAY_BULK_UPLOAD_FAILURE';
export const HOLIDAY_BULK_UPLOAD_RESET = 'HOLIDAY_BULK_UPLOAD_RESET';
