import axios from 'axios';
import API_ROUTE from '../config/appConstants';

const apiGateWay = axios.create({
    baseURL: API_ROUTE,
    //  withCredentials: true,
});

apiGateWay.interceptors.response.use(
    (res) => {
        if (res.data && res.data.statusCode === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
        return res;
    },
    (res) => res
);

apiGateWay.interceptors.request.use((config) => {
    try {
        const token = JSON.parse(sessionStorage.getItem('login-Data'))?.data?.accessToken;
        const tempConfig = {
            ...config,
            headers: {
                ...config.headers,
                'X-Requested-With': 'XMLHttpRequest',
                Authorization: `Bearer ${token}`,
            },
        };
        return tempConfig;
    } catch (error) {
        console.error(`apiGateWay.interceptors.request Error : -`, error);
    }
});

export default apiGateWay;
