import * as actionTypes from './constants';

const initialState = {
    getCountResponse: {},
    getResourceTypeCountResponse: {},
    getHolidaysResponse: {},
    getStudentsCountResponse: {},
    getStudentAttendanceRespone: {},
    getTodaySessionResponse: {},
    getDashAssessmentResponse: {},
};

const dashboardReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COUNT_SUCCESS:
            return {
                ...state,
                getCountResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_COUNT_FAILURE:
            return {
                ...state,
                getCountResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_RESOURCE_TYPE_COUNT_SUCCESS:
            return {
                ...state,
                getResourceTypeCountResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_RESOURCE_TYPE_COUNT_FAILURE:
            return {
                ...state,
                getResourceTypeCountResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_DASH_HOLIDAYS_SUCCESS:
            return {
                ...state,
                getHolidaysResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_DASH_HOLIDAYS_FAILURE:
            return {
                ...state,
                getHolidaysResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_STUDENTS_COUNT_SUCCESS:
            return {
                ...state,
                getStudentsCountResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_STUDENTS_COUNT_FAILURE:
            return {
                ...state,
                getStudentsCountResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_STUDENT_ATTENDANCE_SUCCESS:
            return {
                ...state,
                getStudentAttendanceRespone: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_STUDENT_ATTENDANCE_FAILURE:
            return {
                ...state,
                getStudentAttendanceRespone: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_TODAY_SESSION_SUCCESS:
            return {
                ...state,
                getTodaySessionResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_TODAY_SESSION_FAILURE:
            return {
                ...state,
                getTodaySessionResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_DASH_ASSESSMENT_SUCCESS:
            return {
                ...state,
                getDashAssessmentResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_DASH_ASSESSMENT_FAILURE:
            return {
                ...state,
                getDashAssessmentResponse: { success: false, ...action.payload?.data },
            };
        default:
            return state;
    }
};

export default dashboardReducers;
