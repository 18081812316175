export const GET_COUNT_REQUEST = 'GET_COUNT_REQUEST';
export const GET_COUNT_SUCCESS = 'GET_COUNT_SUCCESS';
export const GET_COUNT_FAILURE = 'GET_COUNT_FAILURE';

export const GET_RESOURCE_TYPE_COUNT_REQUEST = 'GET_RESOURCE_TYPE_COUNT_REQUEST';
export const GET_RESOURCE_TYPE_COUNT_SUCCESS = 'GET_RESOURCE_TYPE_COUNT_SUCCESS';
export const GET_RESOURCE_TYPE_COUNT_FAILURE = 'GET_RESOURCE_TYPE_COUNT_FAILURE';

export const GET_DASH_HOLIDAYS_REQUEST = 'GET_DASH_HOLIDAYS_REQUEST';
export const GET_DASH_HOLIDAYS_SUCCESS = 'GET_DASH_HOLIDAYS_SUCCESS';
export const GET_DASH_HOLIDAYS_FAILURE = 'GET_DASH_HOLIDAYS_FAILURE';

export const GET_STUDENTS_COUNT_REQUEST = 'GET_STUDENTS_COUNT_REQUEST';
export const GET_STUDENTS_COUNT_SUCCESS = 'GET_STUDENTS_COUNT_SUCCESS';
export const GET_STUDENTS_COUNT_FAILURE = 'GET_STUDENTS_COUNT_FAILURE';

export const GET_STUDENT_ATTENDANCE_REQUEST = 'GET_STUDENT_ATTENDANCE_REQUEST';
export const GET_STUDENT_ATTENDANCE_SUCCESS = 'GET_STUDENT_ATTENDANCE_SUCCESS';
export const GET_STUDENT_ATTENDANCE_FAILURE = 'GET_STUDENT_ATTENDANCE_FAILURE';

export const GET_TODAY_SESSION_REQUEST = 'GET_TODAY_SESSION_REQUEST';
export const GET_TODAY_SESSION_SUCCESS = 'GET_TODAY_SESSION_SUCCESS';
export const GET_TODAY_SESSION_FAILURE = 'GET_TODAY_SESSION_FAILURE';

export const GET_DASH_ASSESSMENT_REQUEST = 'GET_DASH_ASSESSMENT_REQUEST';
export const GET_DASH_ASSESSMENT_SUCCESS = 'GET_DASH_ASSESSMENT_SUCCESS';
export const GET_DASH_ASSESSMENT_FAILURE = 'GET_DASH_ASSESSMENT_FAILURE';
