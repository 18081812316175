export const GET_STUDENT_LIST_REQUEST = 'GET_STUDENT_LIST_REQUEST';
export const GET_STUDENT_LIST_SUCCESS = 'GET_STUDENT_LIST_SUCCESS';
export const GET_STUDENT_LIST_FAILURE = 'GET_STUDENT_LIST_FAILURE';

export const GET_CLASS_LIST_REQUEST = 'GET_CLASS_LIST_REQUEST';
export const GET_CLASS_LIST_SUCCESS = 'GET_CLASS_LIST_SUCCESS';
export const GET_CLASS_LIST_FAILURE = 'GET_CLASS_LIST_FAILURE';

export const GET_DIVISION_LIST_REQUEST = 'GET_DIVISION_LIST_REQUEST';
export const GET_DIVISION_LIST_SUCCESS = 'GET_DIVISION_LIST_SUCCESS';
export const GET_DIVISION_LIST_FAILURE = 'GET_DIVISION_LIST_FAILURE';

export const GET_STUDENT_ID_REQUEST = 'GET_STUDENT_ID_REQUEST';
export const GET_STUDENT_ID_SUCCESS = 'GET_STUDENT_ID_SUCCESS';
export const GET_STUDENT_ID_FAILURE = 'GET_STUDENT_ID_FAILURE';

export const GET_STUDENT_ATTENDANCE_REQUEST = 'GET_STUDENT_ATTENDANCE_REQUEST';
export const GET_STUDENT_ATTENDANCE_SUCCESS = 'GET_STUDENT_ATTENDANCE_SUCCESS';
export const GET_STUDENT_ATTENDANCE_FAILURE = 'GET_STUDENT_ATTENDANCE_FAILURE';

export const GET_REMARK_REQUEST = 'GET_REMARK_REQUEST';
export const GET_REMARK_SUCCESS = 'GET_REMARK_SUCCESS';
export const GET_REMARK_FAILURE = 'GET_REMARK_FAILURE';

export const CREATE_STUDENT_REQUEST = 'CREATE_STUDENT_REQUEST';
export const CREATE_STUDENT_SUCCESS = 'CREATE_STUDENT_SUCCESS';
export const CREATE_STUDENT_FAILURE = 'CREATE_STUDENT_FAILURE';
export const CREATE_STUDENT_RESET = 'CREATE_STUDENT_RESET';

export const CREATE_REMARK_REQUEST = 'CREATE_REMARK_REQUEST';
export const CREATE_REMARK_SUCCESS = 'CREATE_REMARK_SUCCESS';
export const CREATE_REMARK_FAILURE = 'CREATE_REMARK_FAILURE';
export const CREATE_REMARK_RESET = 'CREATE_REMARK_RESET';

export const EDIT_STUDENT_REQUEST = 'EDIT_STUDENT_REQUEST';
export const EDIT_STUDENT_SUCCESS = 'EDIT_STUDENT_SUCCESS';
export const EDIT_STUDENT_FAILURE = 'EDIT_STUDENT_FAILURE';
export const EDIT_STUDENT_RESET = 'EDIT_STUDENT_RESET';

export const EDIT_REMARK_REQUEST = 'EDIT_REMARK_REQUEST';
export const EDIT_REMARK_SUCCESS = 'EDIT_REMARK_SUCCESS';
export const EDIT_REMARK_FAILURE = 'EDIT_REMARK_FAILURE';
export const EDIT_REMARK_RESET = 'EDIT_REMARK_RESET';

export const STUDENT_BULK_UPLOAD_SUCCESS = 'STUDENT_BULK_UPLOAD_SUCCESS';
export const STUDENT_BULK_UPLOAD_FAILURE = 'STUDENT_BULK_UPLOAD_FAILURE';
export const STUDENT_BULK_UPLOAD_REQUEST = 'STUDENT_BULK_UPLOAD_REQUEST';

export const DELETE_REMARK_REQUEST = 'DELETE_REMARK_REQUEST';
export const DELETE_REMARK_SUCCESS = 'DELETE_REMARK_SUCCESS';
export const DELETE_REMARK_FAILURE = 'DELETE_REMARK_FAILURE';
export const DELETE_REMARK_RESET = 'DELETE_REMARK_RESET';

export const GET_ROLL_NO_REQUEST = 'GET_ROLL_NO_REQUEST';
export const GET_ROLL_NO_SUCCESS = 'GET_ROLL_NO_SUCCESS';
export const GET_ROLL_NO_FAILURE = 'GET_ROLL_NO_FAILURE';
