import * as actionTypes from './constants';

const initialState = {
    getWorkSheetListResponse: {},
    createWorkSheetResponse: {},
    editWorkSheetResponse: {},
    activateWorksheetResponse: {},
    deleteWorkSheetResponse: {},
};

const workSheetReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_WORK_SHEET_LIST_SUCCESS:
            return {
                ...state,
                getWorkSheetListResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_WORK_SHEET_LIST_FAILURE:
            return {
                ...state,
                getWorkSheetListResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.CREATE_WORK_SHEET_SUCCESS:
            return {
                ...state,
                createWorkSheetResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.CREATE_WORK_SHEET_FAILURE:
            return {
                ...state,
                createWorkSheetResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.EDIT_WORK_SHEET_SUCCESS:
            return {
                ...state,
                editWorkSheetResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.EDIT_WORK_SHEET_FAILURE:
            return {
                ...state,
                editWorkSheetResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.ACTIVATE_WORK_SHEET_SUCCESS:
            return {
                ...state,
                activateWorksheetResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.ACTIVATE_WORK_SHEET_FAILURE:
            return {
                ...state,
                activateWorksheetResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        case actionTypes.DELETE_WORK_SHEET_SUCCESS:
            return {
                ...state,
                deleteWorkSheetResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.DELETE_WORK_SHEET_FAILURE:
            return {
                ...state,
                deleteWorkSheetResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload?.message,
                },
            };
        default:
            return state;
    }
};

export default workSheetReducers;
