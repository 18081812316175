/* eslint-disable no-unneeded-ternary */
export const INVALID = (resource) => `${resource} is invalid.`;

export const EMAIL = (value) => (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(value) ? true : false);

export const VALID_EMAIL = (value) =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
    )
        ? true
        : false;

export const VALID_SIMPLE_EMAIL = (value) => (/(.+)@(.+){2,}\.(.+){2,}/.test(value) ? true : false);

export const PASSWORD = (value) => (/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/.test(value) ? true : false);

export const PHONE_NUMBER = (value) => /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value);

export const DATE = (value) => /^([0-9]{2})\-([0-9]{2})\-([0-9]{4})$/.test(value);

export const FALSY_VALUES = [false, null, undefined, ''];

export const CHECK_FILE_SIZE = (size, limit = 10000000) => size > limit;

export const IS_ALLOWED_IMAGE_FORMAT = (format) => ['png', 'gif', 'jpg'].includes(format);
