import * as serviceEndPoints from '../../config/serviceEndPoints';
import apiGateWay from '../../serviceHandlers/index';

// POST
export const createGroup = (params, paths, payload) => {
    return apiGateWay.post(serviceEndPoints.communicateEndpoints.group, payload);
};
export const createMail = (params, paths, payload) => {
    return apiGateWay.post(serviceEndPoints.communicateEndpoints.mail, payload);
};
export const replyMail = (params, paths, payload) => {
    return apiGateWay.post(`${serviceEndPoints.communicateEndpoints.mail}/reply`, payload);
};

// PUT
export const draftMail = (params, paths, payload) => {
    return apiGateWay.put(`${serviceEndPoints.communicateEndpoints.mail}/save-draft`, payload);
};

// PATCH
export const statusMail = (params, paths, payload) => {
    return apiGateWay.patch(`${serviceEndPoints.communicateEndpoints.mail}/status/${paths?.id}`, payload);
};

// GET
export const getMail = (params, paths, payload) => {
    return apiGateWay.get(serviceEndPoints.communicateEndpoints.mail, { params });
};
export const getLatestMail = (params, paths, payload) => {
    return apiGateWay.get(`${serviceEndPoints.communicateEndpoints.mail}/latest`, { params });
};
export const getDetailMail = (params, paths, payload) => {
    return apiGateWay.get(`${serviceEndPoints.communicateEndpoints.mail}/details/${paths?.id}`, { params });
};
export const getSentMail = (params, paths, payload) => {
    return apiGateWay.get(`${serviceEndPoints.communicateEndpoints.mail}/sent-items`, { params });
};
export const getDraftMail = (params, paths, payload) => {
    return apiGateWay.get(`${serviceEndPoints.communicateEndpoints.mail}/drafts`, { params });
};
export const getTypeMail = (params, paths, payload) => {
    return apiGateWay.get(`${serviceEndPoints.communicateEndpoints.mail}/list/${paths?.type}`, { params });
};
export const getGroup = (params, paths, payload) => {
    return apiGateWay.get(serviceEndPoints.communicateEndpoints.group, { params });
};

export const getIndividual = (params, paths, payload) => {
    return apiGateWay.get(`${serviceEndPoints.communicateEndpoints.group}/individual-users`, { params });
};
