import * as actionTypes from './constants';

const initialState = {
    getAllTopicsResponse: {},
    getAllResourceTypesResponse: {},
    getAllResourceSubTypesResponse: {},
    getQuestionByIdResponse: {},
    createResourceResponse: {},
    editResourceResponse: {},
    createQuestionResponse: {},
    editQuestionResponse: {},
    createCountResponse: {},
    editCountResponse: {},
    createSortResponse: {},
    editSortResponse: {},
    createMatchResponse: {},
    editMatchResponse: {},
    createCircleResponse: {},
    editCircleResponse: {},
    createColorResponse: {},
    editColorResponse: {},
};

const addResourceReducers = (state = initialState, action) => {
    switch (action.type) {
        // GET
        case actionTypes.GET_ALLTOPICS_SUCCESS:
            return {
                ...state,
                getAllTopicsResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_ALLTOPICS_FAILURE:
            return {
                ...state,
                getAllTopicsResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_RESOURCETYPE_SUCCESS:
            return {
                ...state,
                getAllResourceTypesResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_RESOURCETYPE_FAILURE:
            return {
                ...state,
                getAllResourceTypesResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_RESOURCESUBTYPE_SUCCESS:
            return {
                ...state,
                getAllResourceSubTypesResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_RESOURCESUBTYPE_FAILURE:
            return {
                ...state,
                getAllResourceSubTypesResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_QUESTION_BY_ID_SUCCESS:
            return {
                ...state,
                getQuestionByIdResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_QUESTION_BY_ID_FAILURE:
            return {
                ...state,
                getQuestionByIdResponse: { success: false, ...action.payload?.data },
            };
        // POST
        case actionTypes.POST_RESOURCE_SUCCESS:
            return {
                ...state,
                createResourceResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_RESOURCE_FAILURE:
            return {
                ...state,
                createResourceResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_RESOURCE_RESET:
            return {
                ...state,
                createResourceResponse: {},
            };
        case actionTypes.POST_QUESTION_SUCCESS:
            return {
                ...state,
                createQuestionResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_QUESTION_FAILURE:
            return {
                ...state,
                createQuestionResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.POST_QUESTION_RESET:
            return {
                ...state,
                createQuestionResponse: {},
            };
        case actionTypes.POST_CREATECOUNT_SUCCESS:
            return {
                ...state,
                createCountResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_CREATECOUNT_FAILURE:
            return {
                ...state,
                createCountResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_CREATECOUNT_RESET:
            return {
                ...state,
                createCountResponse: {},
            };
        case actionTypes.POST_CREATESORT_SUCCESS:
            return {
                ...state,
                createSortResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_CREATESORT_FAILURE:
            return {
                ...state,
                createSortResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_CREATESORT_RESET:
            return {
                ...state,
                createSortResponse: {},
            };
        case actionTypes.POST_CREATEMATCH_SUCCESS:
            return {
                ...state,
                createMatchResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_CREATEMATCH_FAILURE:
            return {
                ...state,
                createMatchResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_CREATEMATCH_RESET:
            return {
                ...state,
                createMatchResponse: {},
            };
        case actionTypes.POST_CREATECIRCLE_SUCCESS:
            return {
                ...state,
                createCircleResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_CREATECIRCLE_FAILURE:
            return {
                ...state,
                createCircleResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_CREATECIRCLE_RESET:
            return {
                ...state,
                createCircleResponse: {},
            };
        case actionTypes.POST_CREATECOLOUR_SUCCESS:
            return {
                ...state,
                createColorResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_CREATECOLOUR_FAILURE:
            return {
                ...state,
                createColorResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_CREATECOLOUR_RESET:
            return {
                ...state,
                createColorResponse: {},
            };
        // PATCH
        case actionTypes.PATCH_RESOURCE_SUCCESS:
            return {
                ...state,
                editResourceResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_RESOURCE_FAILURE:
            return {
                ...state,
                editResourceResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_RESOURCE_RESET:
            return {
                ...state,
                editResourceResponse: {},
            };

        case actionTypes.PATCH_QUESTION_SUCCESS:
            return {
                ...state,
                editQuestionResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_QUESTION_FAILURE:
            return {
                ...state,
                editQuestionResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.PATCH_QUESTION_RESET:
            return {
                ...state,
                editQuestionResponse: {},
            };
        case actionTypes.PATCH_EDITCOUNT_SUCCESS:
            return {
                ...state,
                editCountResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_EDITCOUNT_FAILURE:
            return {
                ...state,
                editCountResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_EDITCOUNT_RESET:
            return {
                ...state,
                editCountResponse: {},
            };
        case actionTypes.PATCH_EDITSORT_SUCCESS:
            return {
                ...state,
                editSortResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_EDITSORT_FAILURE:
            return {
                ...state,
                editSortResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_EDITSORT_RESET:
            return {
                ...state,
                editSortResponse: {},
            };
        case actionTypes.PATCH_EDITCIRCLE_SUCCESS:
            return {
                ...state,
                editCircleResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_EDITCIRCLE_FAILURE:
            return {
                ...state,
                editCircleResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_EDITCIRCLE_RESET:
            return {
                ...state,
                editCircleResponse: {},
            };
        case actionTypes.PATCH_EDITCOLOUR_SUCCESS:
            return {
                ...state,
                editColorResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_EDITCOLOUR_FAILURE:
            return {
                ...state,
                editColorResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_EDITCOLOUR_RESET:
            return {
                ...state,
                editColorResponse: {},
            };
        // PUT
        case actionTypes.PUT_EDITMATCH_SUCCESS:
            return {
                ...state,
                editMatchResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PUT_EDITMATCH_FAILURE:
            return {
                ...state,
                editMatchResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PUT_EDITMATCH_RESET:
            return {
                ...state,
                editMatchResponse: {},
            };

        default:
            return state;
    }
};

export default addResourceReducers;
