import * as actionTypes from './constants';

const initialState = {
    getSignatureResponse: {},
};

const zoomReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SIGNATURE_SUCCESS:
            return {
                ...state,
                getSignatureResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_SIGNATURE_FAILURE:
            return {
                ...state,
                getSignatureResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        default:
            return state;
    }
};

export default zoomReducers;
