import * as actionTypes from './constants';

const initialState = {
    getAssignedClassListResponse: {},
    getAllTeachersResponse: {},
    createAssignedClassResponse: {},
    updateAssignedClassResponse: {},
    deleteAssignedClassResponse: {},
};

const assignClassReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ASSIGNED_CLASS_SUCCESS:
            return {
                ...state,
                getAssignedClassListResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_ASSIGNED_CLASS_FAILURE:
            return {
                ...state,
                getAssignedClassListResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_ALL_TEACHERS_SUCCESS:
            return {
                ...state,
                getAllTeachersResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_ALL_TEACHERS_FAILURE:
            return {
                ...state,
                getAllTeachersResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.CREATE_ASSIGNED_CLASS_SUCCESS:
            return {
                ...state,
                createAssignedClassResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.CREATE_ASSIGNED_CLASS_FAILURE:
            return {
                ...state,
                createAssignedClassResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.UPDATE_ASSIGNED_CLASS_SUCCESS:
            return {
                ...state,
                updateAssignedClassResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.UPDATE_ASSIGNED_CLASS_FAILURE:
            return {
                ...state,
                updateAssignedClassResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.DELETE_ASSIGNED_CLASS_SUCCESS:
            return {
                ...state,
                deleteAssignedClassResponse: {
                    success: true,
                    ...action.payload?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.DELETE_ASSIGNED_CLASS_FAILURE:
            return {
                ...state,
                deleteAssignedClassResponse: {
                    success: false,
                    ...action.payload?.data,
                    message: action.payload.message,
                },
            };
        default:
            return state;
    }
};

export default assignClassReducers;
