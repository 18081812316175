import React from 'react';

const AnimatedObjects = (props) => {
    const { position } = props;
    const objectList = [
        {
            image: 'flower-1.gif',
            style: 'object-type type-1',
        },
        {
            image: 'flower-2.gif',
            style: 'object-type type-2',
        },
        {
            image: 'honey-bee.gif',
            style: 'object-type type-3',
        },
        {
            image: 'balloon-1.gif',
            style: 'object-type type-1',
        },
        {
            image: 'balloon-2.gif',
            style: 'object-type type-1',
        },
        {
            image: 'butterfly.gif',
            style: 'object-type type-3',
        },
    ];
    const imgIndex = Math.floor(Math.random() * objectList.length);
    return (
        <div className="animated-objects">
            <img
                src={`/images/animation/${objectList[imgIndex].image}`}
                alt=""
                className={`${objectList[imgIndex].style} ${position}`}
            />
        </div>
    );
};

export default AnimatedObjects;
