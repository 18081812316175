import * as actionTypes from './constants';

const initialState = {
    getMailResponse: {},
    getLatestMailResponse: {},
    getSentMailResponse: {},
    getDraftMailResponse: {},
    getTypeMailResponse: {},
    getDetailMailResponse: {},
    createGroupResponse: {},
    getGroupResponse: {},
    getIndividualResponse: {},
    createMailResponse: {},
    replyMailResponse: {},
    createDraftMailResponse: {},
    statusUpdateResponse: {},
    groupLoader: false,
    individualLoader: false,
};

const communicateReducers = (state = initialState, action) => {
    switch (action.type) {
        // POST
        case actionTypes.POST_GROUP_SUCCESS:
            return {
                ...state,
                createGroupResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_GROUP_FAILURE:
            return {
                ...state,
                createGroupResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_GROUP_RESET:
            return {
                ...state,
                createGroupResponse: {},
            };
        case actionTypes.POST_MAIL_SUCCESS:
            return {
                ...state,
                createMailResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_MAIL_FAILURE:
            return {
                ...state,
                createMailResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_MAIL_RESET:
            return {
                ...state,
                createMailResponse: {},
            };
        case actionTypes.POST_REPLYMAIL_SUCCESS:
            return {
                ...state,
                replyMailResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_REPLYMAIL_FAILURE:
            return {
                ...state,
                replyMailResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_REPLYMAIL_RESET:
            return {
                ...state,
                replyMailResponse: {},
            };
        // PUT
        case actionTypes.PUT_DRAFTMAIL_SUCCESS:
            return {
                ...state,
                createDraftMailResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PUT_DRAFTMAIL_FAILURE:
            return {
                ...state,
                createDraftMailResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PUT_DRAFTMAIL_RESET:
            return {
                ...state,
                createDraftMailResponse: {},
            };

        // PATCH
        case actionTypes.PATCH_STATUSMAIL_SUCCESS:
            return {
                ...state,
                statusUpdateResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_STATUSMAIL_FAILURE:
            return {
                ...state,
                statusUpdateResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PATCH_STATUSMAIL_RESET:
            return {
                ...state,
                statusUpdateResponse: {},
            };

        // GET
        case actionTypes.GET_MAIL_SUCCESS:
            return {
                ...state,
                getMailResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_MAIL_FAILURE:
            return {
                ...state,
                getMailResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_LATESTMAIL_SUCCESS:
            return {
                ...state,
                getLatestMailResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_LATESTMAIL_FAILURE:
            return {
                ...state,
                getLatestMailResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_DETAILMAIL_SUCCESS:
            return {
                ...state,
                getDetailMailResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_DETAILMAIL_FAILURE:
            return {
                ...state,
                getDetailMailResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_SENTMAIL_SUCCESS:
            return {
                ...state,
                getSentMailResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_SENTMAIL_FAILURE:
            return {
                ...state,
                getSentMailResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_DRAFTMAIL_SUCCESS:
            return {
                ...state,
                getDraftMailResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_DRAFTMAIL_FAILURE:
            return {
                ...state,
                getDraftMailResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_TYPEMAIL_SUCCESS:
            return {
                ...state,
                getTypeMailResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_TYPEMAIL_FAILURE:
            return {
                ...state,
                getTypeMailResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_GROUP_SUCCESS:
            return {
                ...state,
                getGroupResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_GROUP_FAILURE:
            return {
                ...state,
                getGroupResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_GROUP_LOADING:
            return {
                ...state,
                groupLoader: true,
            };
        case actionTypes.GET_GROUP_LOADED:
            return {
                ...state,
                groupLoader: false,
            };
        case actionTypes.GET_INDIVIDUAL_SUCCESS:
            return {
                ...state,
                getIndividualResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_INDIVIDUAL_FAILURE:
            return {
                ...state,
                getIndividualResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_INDIVIDUAL_LOADING:
            return {
                ...state,
                individualLoader: true,
            };
        case actionTypes.GET_INDIVIDUAL_LOADED:
            return {
                ...state,
                individualLoader: false,
            };
        default:
            return state;
    }
};

export default communicateReducers;
