export const SEND_LOGIN_REQUEST = 'SEND_LOGIN_REQUEST';
export const SEND_LOGIN_SUCCESS = 'SEND_LOGIN_SUCCESS';
export const SEND_LOGIN_FAILURE = 'SEND_LOGIN_FAILURE';

export const SEND_FORGET_REQUEST = 'SEND_FORGET_REQUEST';
export const SEND_FORGET_SUCCESS = 'SEND_FORGET_SUCCESS';
export const SEND_FORGET_FAILURE = 'SEND_FORGET_FAILURE';

export const SEND_CHECK_REQUEST = 'SEND_CHECK_REQUEST';
export const SEND_CHECK_SUCCESS = 'SEND_CHECK_SUCCESS';
export const SEND_CHECK_FAILURE = 'SEND_CHECK_FAILURE';

export const SEND_RESET_REQUEST = 'SEND_RESET_REQUEST';
export const SEND_RESET_SUCCESS = 'SEND_RESET_SUCCESS';
export const SEND_RESET_FAILURE = 'SEND_RESET_FAILURE';

export const GET_ADDHOME_REQUEST = 'GET_ADDHOME_REQUEST';
export const GET_ADDHOME_SUCCESS = 'GET_ADDHOME_SUCCESS';
export const GET_ADDHOME_FAILURE = 'GET_ADDHOME_FAILURE';
