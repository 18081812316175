import * as commonTypes from '../common/constants';
import * as actionTypes from './constants';
import * as service from './services';
import { request } from '../../utils/utils';

// POST
export const createGroup = (payload) => async (dispatch) => {
    request({
        dispatch,
        api: service.createGroup,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.POST_GROUP_REQUEST }],
        successActions: [
            { type: actionTypes.POST_GROUP_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.POST_GROUP_REQUEST },
            { type: actionTypes.POST_GROUP_RESET, payload: null },
        ],
        failureActions: [
            { type: actionTypes.POST_GROUP_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.POST_GROUP_REQUEST },
        ],
    });
};

export const createMail = (payload) => async (dispatch) => {
    request({
        dispatch,
        api: service.createMail,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.POST_MAIL_REQUEST }],
        successActions: [
            { type: actionTypes.POST_MAIL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.POST_MAIL_REQUEST },
            { type: actionTypes.POST_MAIL_RESET, payload: null },
        ],
        failureActions: [
            { type: actionTypes.POST_MAIL_FAILURE, error: null },
            { type: actionTypes.POST_MAIL_RESET, payload: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.POST_MAIL_REQUEST },
        ],
    });
};
export const replyMail = (payload) => async (dispatch) => {
    request({
        dispatch,
        api: service.replyMail,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.POST_REPLYMAIL_REQUEST }],
        successActions: [
            { type: actionTypes.POST_REPLYMAIL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.POST_REPLYMAIL_REQUEST },
            { type: actionTypes.POST_REPLYMAIL_RESET, payload: null },
        ],
        failureActions: [
            { type: actionTypes.POST_REPLYMAIL_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.POST_REPLYMAIL_REQUEST },
        ],
    });
};

// PUT
export const draftMail = (payload) => async (dispatch) => {
    request({
        dispatch,
        api: service.draftMail,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.PUT_DRAFTMAIL_REQUEST }],
        successActions: [
            { type: actionTypes.PUT_DRAFTMAIL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.PUT_DRAFTMAIL_REQUEST },
            { type: actionTypes.PUT_DRAFTMAIL_RESET, payload: null },
        ],
        failureActions: [
            { type: actionTypes.PUT_DRAFTMAIL_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.PUT_DRAFTMAIL_REQUEST },
        ],
    });
};

// PATCH
export const statusMail = (paths, payload) => async (dispatch) => {
    request({
        dispatch,
        api: service.statusMail,
        paths,
        payload,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.PATCH_STATUSMAIL_REQUEST }],
        successActions: [
            { type: actionTypes.PATCH_STATUSMAIL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.PATCH_STATUSMAIL_REQUEST },
            { type: actionTypes.PATCH_STATUSMAIL_RESET, payload: null },
        ],
        failureActions: [
            { type: actionTypes.PATCH_STATUSMAIL_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.PATCH_STATUSMAIL_REQUEST },
        ],
    });
};

// GET
export const getMail = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getMail,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.GET_MAIL_REQUEST }],
        successActions: [
            { type: actionTypes.GET_MAIL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_MAIL_REQUEST },
        ],
        failureActions: [
            { type: actionTypes.GET_MAIL_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_MAIL_REQUEST },
        ],
    });
};
export const getLatestMail = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getLatestMail,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.GET_LATESTMAIL_REQUEST }],
        successActions: [
            { type: actionTypes.GET_LATESTMAIL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_LATESTMAIL_REQUEST },
        ],
        failureActions: [
            { type: actionTypes.GET_LATESTMAIL_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_LATESTMAIL_REQUEST },
        ],
    });
};
export const getDetailMail = (params, paths) => async (dispatch) => {
    request({
        dispatch,
        api: service.getDetailMail,
        params,
        paths,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.GET_DETAILMAIL_REQUEST }],
        successActions: [
            { type: actionTypes.GET_DETAILMAIL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_DETAILMAIL_REQUEST },
        ],
        failureActions: [
            { type: actionTypes.GET_DETAILMAIL_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_DETAILMAIL_REQUEST },
        ],
    });
};

export const getSentMail = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getSentMail,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.GET_SENTMAIL_REQUEST }],
        successActions: [
            { type: actionTypes.GET_SENTMAIL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_SENTMAIL_REQUEST },
        ],
        failureActions: [
            { type: actionTypes.GET_SENTMAIL_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_SENTMAIL_REQUEST },
        ],
    });
};
export const getDraftMail = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getDraftMail,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.GET_DRAFTMAIL_REQUEST }],
        successActions: [
            { type: actionTypes.GET_DRAFTMAIL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_DRAFTMAIL_REQUEST },
        ],
        failureActions: [
            { type: actionTypes.GET_DRAFTMAIL_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_DRAFTMAIL_REQUEST },
        ],
    });
};
export const getTypeMail = (params, paths) => async (dispatch) => {
    request({
        dispatch,
        api: service.getTypeMail,
        params,
        paths,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.GET_TYPEMAIL_REQUEST }],
        successActions: [
            { type: actionTypes.GET_TYPEMAIL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_TYPEMAIL_REQUEST },
        ],
        failureActions: [
            { type: actionTypes.GET_TYPEMAIL_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_TYPEMAIL_REQUEST },
        ],
    });
};

export const getGroup = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getGroup,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.GET_GROUP_REQUEST }],
        customActions: [{ type: actionTypes.GET_GROUP_LOADING, payload: actionTypes.GET_GROUP_REQUEST }],
        successActions: [
            { type: actionTypes.GET_GROUP_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_GROUP_REQUEST },
            { type: actionTypes.GET_GROUP_LOADED, payload: actionTypes.GET_GROUP_REQUEST },
        ],
        failureActions: [
            { type: actionTypes.GET_GROUP_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_GROUP_REQUEST },
            { type: actionTypes.GET_GROUP_LOADED, payload: actionTypes.GET_GROUP_REQUEST },
        ],
    });
};

export const getIndividual = (params) => async (dispatch) => {
    request({
        dispatch,
        api: service.getIndividual,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: actionTypes.GET_INDIVIDUAL_REQUEST }],
        customActions: [{ type: actionTypes.GET_INDIVIDUAL_LOADING, payload: actionTypes.GET_INDIVIDUAL_REQUEST }],
        successActions: [
            { type: actionTypes.GET_INDIVIDUAL_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_INDIVIDUAL_REQUEST },
            { type: actionTypes.GET_INDIVIDUAL_LOADED, payload: actionTypes.GET_INDIVIDUAL_REQUEST },
        ],
        failureActions: [
            { type: actionTypes.GET_GROUP_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: actionTypes.GET_GROUP_REQUEST },
            { type: actionTypes.GET_GROUP_LOADED, payload: actionTypes.GET_GROUP_REQUEST },
        ],
    });
};
