export const SET_ZOOM_DATA = 'SET_ZOOM_DATA';
export const RESET_ZOOM_DATA = 'RESET_ZOOM_DATA';

export const GET_SESSION_RESOURCE_REQUEST = 'GET_SESSION_RESOURCE_REQUEST';
export const GET_SESSION_RESOURCE_SUCCESS = 'GET_SESSION_RESOURCE_SUCCESS';
export const GET_SESSION_RESOURCE_FAILURE = 'GET_SESSION_RESOURCE_FAILURE';

export const GET_SESSION_CUSTOM_RESOURCE_REQUEST = 'GET_SESSION_CUSTOM_RESOURCE_REQUEST';
export const GET_SESSION_CUSTOM_RESOURCE_SUCCESS = 'GET_SESSION_CUSTOM_RESOURCE_SUCCESS';
export const GET_SESSION_CUSTOM_RESOURCE_FAILURE = 'GET_SESSION_CUSTOM_RESOURCE_FAILURE';

export const GET_SESSION_ASSESMENT_REQUEST = 'GET_SESSION_ASSESMENT_REQUEST';
export const GET_SESSION_ASSESMENT_SUCCESS = 'GET_SESSION_ASSESMENT_SUCCESS';
export const GET_SESSION_ASSESMENT_FAILURE = 'GET_SESSION_ASSESMENT_FAILURE';

export const GET_SESSION_RESULT_REQUEST = 'GET_SESSION_RESULT_REQUEST';
export const GET_SESSION_RESULT_SUCCESS = 'GET_SESSION_RESULT_SUCCESS';
export const GET_SESSION_RESULT_FAILURE = 'GET_SESSION_RESULT_FAILURE';

export const GET_SESSION_ATTENDANCE_REQUEST = 'GET_SESSION_ATTENDANCE_REQUEST';
export const GET_SESSION_ATTENDANCE_SUCCESS = 'GET_SESSION_ATTENDANCE_SUCCESS';
export const GET_SESSION_ATTENDANCE_FAILURE = 'GET_SESSION_ATTENDANCE_FAILURE';
