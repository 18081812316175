import * as actionType from './constants';

const initialState = {
    loginUserResponse: {},
    forgetUserResponse: {},
    checkUserResponse: {},
    resetUserResponse: {},
    getHomeResponse: {},
};

const userReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionType.SEND_LOGIN_REQUEST:
            return {
                ...state,
            };
        case actionType.SEND_LOGIN_SUCCESS:
            return {
                ...state,
                loginUserResponse: { success: true, ...action.payload },
            };
        case actionType.SEND_LOGIN_FAILURE:
            return {
                ...state,
                loginUserResponse: { success: false, ...action.payload },
            };
        case actionType.SEND_FORGET_REQUEST:
            return {
                ...state,
            };
        case actionType.SEND_FORGET_SUCCESS:
            return {
                ...state,
                forgetUserResponse: { success: true, ...action.payload },
            };
        case actionType.SEND_FORGET_FAILURE:
            return {
                ...state,
                forgetUserResponse: { success: false, ...action.payload },
            };
        case actionType.SEND_CHECK_REQUEST:
            return {
                ...state,
            };
        case actionType.SEND_CHECK_SUCCESS:
            return {
                ...state,
                checkUserResponse: { success: true, ...action.payload },
            };
        case actionType.SEND_CHECK_FAILURE:
            return {
                ...state,
                checkUserResponse: { success: false, ...action.payload },
            };
        case actionType.SEND_RESET_REQUEST:
            return {
                ...state,
            };
        case actionType.SEND_RESET_SUCCESS:
            return {
                ...state,
                resetUserResponse: { success: true, ...action.payload },
            };
        case actionType.SEND_RESET_FAILURE:
            return {
                ...state,
                resetUserResponse: { success: false, ...action.payload },
            };
        case actionType.GET_ADDHOME_REQUEST:
            return {
                ...state,
            };
        case actionType.GET_ADDHOME_SUCCESS:
            return {
                ...state,
                getHomeResponse: { success: true, ...action.payload },
            };
        case actionType.GET_ADDHOME_FAILURE:
            return {
                ...state,
                getHomeResponse: { success: false, ...action.payload },
            };
        default:
            return state;
    }
};

export default userReducers;
