/* eslint-disable no-alert */
import React, { useEffect } from 'react';
import Header from '../components/common/Header';

const PreviewRoute = ({ children }) => {
    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = '';
    };

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser);
        return () => {
            window.removeEventListener('beforeunload', alertUser);
        };
    }, []);

    return (
        <div className="page-wrapper">
            <div className="page-wrapper-grid">
                <div className="content-page no-sidebar">
                    <Header interactive hideinfo />
                    {children}
                </div>
            </div>
        </div>
    );
};

export default PreviewRoute;
