import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const SchoolHeader = () => {
    const [schoolName, setSchool] = useState('');
    const [branchName, setBranch] = useState('');

    const { details: { school, branch, teacher, user } = {} } = useSelector(
        (state) => state.commonReducers.getBreadCrumbDataResponse
    );

    useEffect(() => {
        (() => {
            if (teacher) {
                if (teacher?.school?.school_name) {
                    setSchool(teacher?.school?.school_name);
                } else {
                    setSchool('');
                }
                if (teacher?.branch?.branch_name) {
                    setBranch(teacher?.branch?.branch_name);
                } else {
                    setBranch('');
                }
            } else if (user) {
                if (user?.school?.school_name) {
                    setSchool(user?.school?.school_name);
                } else {
                    setSchool('');
                }
                if (user?.branch?.branch_name) {
                    setBranch(user?.branch?.branch_name);
                } else {
                    setBranch('');
                }
            } else {
                if (school?.school_name) {
                    setSchool(school?.school_name);
                } else {
                    setSchool('');
                }
                if (branch?.branch_name) {
                    setBranch(branch?.branch_name);
                } else {
                    setBranch('');
                }
            }
        })();
    }, [school, branch, teacher, user]);

    return (
        (schoolName || branchName) && (
            <div className="school_header_container d-flex  align-items-center">
                <i className="bi bi-building" />
                <p className="school_header ">{schoolName}</p>
                {branchName && <p className="branch_header">/ {branchName}</p>}
            </div>
        )
    );
};

export default SchoolHeader;
