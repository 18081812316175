import React from 'react';

const NoAccess = () => {
    return (
        <div className="content">
            <div className=" _comingsoon">
                <p>You do not have access to this page. Please contact admin</p>
            </div>
        </div>
    );
};

export default NoAccess;
