import * as commonTypes from './constants';
import { request } from '../../utils/utils';
import * as Service from './services';

export const getAllowedPermissions = (params) => async (dispatch) => {
    request({
        dispatch,
        api: Service.allowedPermissions,
        params,
        requestActions: [{ type: commonTypes.ADD_LOADER, payload: commonTypes.UPDATE_SIDEBAR_REQUEST }],
        successActions: [
            { type: commonTypes.UPDATE_SIDEBAR_SUCCESS, response: null },
            { type: commonTypes.REMOVE_LOADER, payload: commonTypes.UPDATE_SIDEBAR_REQUEST },
        ],
        failureActions: [
            { type: commonTypes.UPDATE_SIDEBAR_FAILURE, error: null },
            { type: commonTypes.REMOVE_LOADER, payload: commonTypes.UPDATE_SIDEBAR_REQUEST },
        ],
    });
};

export const getBreadCrumbData = (params) => async (dispatch) => {
    request({
        dispatch,
        api: Service.getBreadCrumbData,
        params,
        requestActions: [],
        successActions: [{ type: commonTypes.GET_BREADCRUMB_DATA_SUCCESS, response: null }],
        failureActions: [{ type: commonTypes.GET_BREADCRUMB_DATA_FAILURE, error: null }],
    });
};
