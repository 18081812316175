export const GET_SESSION_REQUEST = 'GET_SESSION_REQUEST';
export const GET_SESSION_SUCCESS = 'GET_SESSION_SUCCESS';
export const GET_SESSION_FAILURE = 'GET_SESSION_FAILURE';

export const GENERATE_FILE_REQUEST = 'GENERATE_FILE_REQUEST';
export const GENERATE_FILE_SUCCESS = 'GENERATE_FILE_SUCCESS';
export const GENERATE_FILE_FAILURE = 'GENERATE_FILE_FAILURE';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const UPDATE_SESSION_SUBJECT_REQUEST = 'UPDATE_SESSION_SUBJECT_REQUEST';
export const UPDATE_SESSION_SUBJECT_SUCCESS = 'UPDATE_SESSION_SUBJECT_SUCCESS';
export const UPDATE_SESSION_SUBJECT_FAILURE = 'UPDATE_SESSION_SUBJECT_FAILURE';

export const GET_TIME_TABLE_LIST_REQUEST = 'GET_TIME_TABLE_LIST_REQUEST';
export const GET_TIME_TABLE_LIST_SUCCESS = 'GET_TIME_TABLE_LIST_SUCCESS';
export const GET_TIME_TABLE_LIST_FAILURE = 'GET_TIME_TABLE_LIST_FAILURE';

export const CREATE_HOLIDAY_REQUEST = 'CREATE_HOLIDAY_REQUEST';
export const CREATE_HOLIDAY_SUCCESS = 'CREATE_HOLIDAY_SUCCESS';
export const CREATE_HOLIDAY_FAILURE = 'CREATE_HOLIDAY_FAILURE';
