export const GET_WORK_SHEET_LIST_REQUEST = 'GET_WORK_SHEET_LIST_REQUEST';
export const GET_WORK_SHEET_LIST_SUCCESS = 'GET_WORK_SHEET_LIST_SUCCESS';
export const GET_WORK_SHEET_LIST_FAILURE = 'GET_WORK_SHEET_LIST_FAILURE';

export const CREATE_WORK_SHEET_REQUEST = 'CREATE_WORK_SHEET_REQUEST';
export const CREATE_WORK_SHEET_SUCCESS = 'CREATE_WORK_SHEET_SUCCESS';
export const CREATE_WORK_SHEET_FAILURE = 'CREATE_WORK_SHEET_FAILURE';

export const EDIT_WORK_SHEET_REQUEST = 'EDIT_WORK_SHEET_REQUEST';
export const EDIT_WORK_SHEET_SUCCESS = 'EDIT_WORK_SHEET_SUCCESS';
export const EDIT_WORK_SHEET_FAILURE = 'EDIT_WORK_SHEET_FAILURE';

export const ACTIVATE_WORK_SHEET_REQUEST = 'ACTIVATE_WORK_SHEET_REQUEST';
export const ACTIVATE_WORK_SHEET_SUCCESS = 'ACTIVATE_WORK_SHEET_SUCCESS';
export const ACTIVATE_WORK_SHEET_FAILURE = 'ACTIVATE_WORK_SHEET_FAILURE';

export const DELETE_WORK_SHEET_REQUEST = 'DELETE_WORK_SHEET_REQUEST';
export const DELETE_WORK_SHEET_SUCCESS = 'DELETE_WORK_SHEET_SUCCESS';
export const DELETE_WORK_SHEET_FAILURE = 'DELETE_WORK_SHEET_FAILURE';
