export const GET_PARCTICE_URL_REQUEST = 'GET_PARCTICE_URL_REQUEST';
export const GET_PARCTICE_URL_SUCCESS = 'GET_PARCTICE_URL_SUCCESS';
export const GET_PARCTICE_URL_FAILURE = 'GET_PARCTICE_URL_FAILURE';

export const GET_PARCTICE_ASSESSMENT_REQUEST = 'GET_PARCTICE_ASSESSMENT_REQUEST';
export const GET_PARCTICE_ASSESSMENT_SUCCESS = 'GET_PARCTICE_ASSESSMENT_SUCCESS';
export const GET_PARCTICE_ASSESSMENT_FAILURE = 'GET_PARCTICE_ASSESSMENT_FAILURE';

export const GET_PARCTICE_SUBMIT_REQUEST = 'GET_PARCTICE_SUBMIT_REQUEST';
export const GET_PARCTICE_SUBMIT_SUCCESS = 'GET_PARCTICE_SUBMIT_SUCCESS';
export const GET_PARCTICE_SUBMIT_FAILURE = 'GET_PARCTICE_SUBMIT_FAILURE';
