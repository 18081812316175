import apiGateWay from '../../serviceHandlers';
import { attendance, teacherEndPoints } from '../../config/serviceEndPoints';
// *Attendance
export const getAttendance = (params) => {
    return apiGateWay.get(`${attendance.getList}`, { params });
};

export const getClassDivisionsByTeacherId = ({ teacher_id }) => {
    return apiGateWay.get(`${teacherEndPoints.getTeacherById}/${teacher_id}/class-divisions`);
};
