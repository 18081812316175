export const GET_SESSION_PLAN_LIST_REQUEST = 'GET_SESSION_PLAN_LIST_REQUEST';
export const GET_SESSION_PLAN_LIST_SUCCESS = 'GET_SESSION_PLAN_LIST_SUCCESS';
export const GET_SESSION_PLAN_LIST_FAILURE = 'GET_SESSION_PLAN_LIST_FAILURE';
export const GET_SESSION_PLAN_LIST_CLEAR = 'GET_SESSION_PLAN_LIST_CLEAR';

export const GET_SESSIONS_REQUEST = 'GET_SESSIONS_REQUEST';
export const GET_SESSIONS_SUCCESS = 'GET_SESSIONS_SUCCESS';
export const GET_SESSIONS_FAILURE = 'GET_SESSIONS_FAILURE';

export const GET_ASSIGNED_TEACHERS_REQUEST = 'GET_ASSIGNED_TEACHERS_REQUEST';
export const GET_ASSIGNED_TEACHERS_SUCCESS = 'GET_ASSIGNED_TEACHERS_SUCCESS';
export const GET_ASSIGNED_TEACHERS_FAILURE = 'GET_ASSIGNED_TEACHERS_FAILURE';
export const GET_ASSIGNED_TEACHERS_RESET = 'GET_ASSIGNED_TEACHERS_RESET';

export const GET_ALL_RESOURCES_REQUEST = 'GET_ALL_RESOURCES_REQUEST';
export const GET_ALL_RESOURCES_SUCCESS = 'GET_ALL_RESOURCES_SUCCESS';
export const GET_ALL_RESOURCES_FAILURE = 'GET_ALL_RESOURCES_FAILURE';
export const GET_ALL_RESOURCES_RESET = 'GET_ALL_RESOURCES_RESET';

export const GET_ALL_ASSESSMENT_REQUEST = 'GET_ALL_ASSESSMENT_REQUEST';
export const GET_ALL_ASSESSMENT_SUCCESS = 'GET_ALL_ASSESSMENT_SUCCESS';
export const GET_ALL_ASSESSMENT_FAILURE = 'GET_ALL_ASSESSMENT_FAILURE';
export const GET_ALL_ASSESSMENT_RESET = 'GET_ALL_ASSESSMENT_RESET';

export const CREATE_SESSION_PLAN_REQUEST = 'CREATE_SESSION_PLAN_REQUEST';
export const CREATE_SESSION_PLAN_SUCCESS = 'CREATE_SESSION_PLAN_SUCCESS';
export const CREATE_SESSION_PLAN_FAILURE = 'CREATE_SESSION_PLAN_FAILURE';

export const EDIT_SESSION_PLAN_REQUEST = 'EDIT_SESSION_PLAN_REQUEST';
export const EDIT_SESSION_PLAN_SUCCESS = 'EDIT_SESSION_PLAN_SUCCESS';
export const EDIT_SESSION_PLAN_FAILURE = 'EDIT_SESSION_PLAN_FAILURE';

export const DELETE_SESSION_PLAN_REQUEST = 'DELETE_SESSION_PLAN_REQUEST';
export const DELETE_SESSION_PLAN_SUCCESS = 'DELETE_SESSION_PLAN_SUCCESS';
export const DELETE_SESSION_PLAN_FAILURE = 'DELETE_SESSION_PLAN_FAILURE';

export const DELETE_CUSTOM_RESOURCE_REQUEST = 'DELETE_CUSTOM_RESOURCE_REQUEST';
export const DELETE_CUSTOM_RESOURCE_SUCCESS = 'DELETE_CUSTOM_RESOURCE_SUCCESS';
export const DELETE_CUSTOM_RESOURCE_FAILURE = 'DELETE_CUSTOM_RESOURCE_FAILURE';

export const UPDATE_HAS_SESSION_PLAN = 'UPDATE_HAS_SESSION_PLAN';
export const RESET_HAS_SESSION_PLAN = 'RESET_HAS_SESSION_PLAN';
