/* eslint-disable no-alert */
import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../components/common/Header';

const InteractiveRoute = ({ children, isLoggedIn, profile }) => {
    const alertUser = (e) => {
        e.preventDefault();
        e.returnValue = '';
    };

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser);
        return () => {
            window.removeEventListener('beforeunload', alertUser);
        };
    }, []);

    if (profile) {
        if (!['STUDENT', 'TEACHER'].includes(profile)) return <Navigate to="/" />;
    }

    return isLoggedIn ? (
        <div className="page-wrapper">
            <div className="page-wrapper-grid">
                <div className="content-page no-sidebar">
                    <Header interactive />
                    {children}
                </div>
            </div>
        </div>
    ) : (
        <Navigate to="/sign-in" />
    );
};

export default InteractiveRoute;
