import * as actionTypes from './constants';

const initialState = {
    getHolidayTypeResponse: {},
    createHolidayResponse: {},
    getHolidayResponse: {},
    editHolidayResponse: {},
    deleteHolidayResponse: {},
    bulkUploadResponse: {},
};

const holidayManagementReducers = (state = initialState, action) => {
    switch (action.type) {
        // GET
        case actionTypes.GET_HOLIDAY_TYPE_SUCCESS:
            return {
                ...state,
                getHolidayTypeResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_HOLIDAY_TYPE_FAILURE:
            return {
                ...state,
                getHolidayTypeResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_HOLIDAY_SUCCESS:
            return {
                ...state,
                getHolidayResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_HOLIDAY_FAILURE:
            return {
                ...state,
                getHolidayResponse: { success: false, ...action.payload?.data },
            };

        // POST
        case actionTypes.POST_HOLIDAY_SUCCESS:
            return {
                ...state,
                createHolidayResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_HOLIDAY_FAILURE:
            return {
                ...state,
                createHolidayResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.POST_HOLIDAY_RESET:
            return {
                ...state,
                createHolidayResponse: {},
            };
        case actionTypes.HOLIDAY_BULK_UPLOAD_SUCCESS:
            return {
                ...state,
                bulkUploadResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.HOLIDAY_BULK_UPLOAD_FAILURE:
            return {
                ...state,
                bulkUploadResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.HOLIDAY_BULK_UPLOAD_RESET:
            return {
                ...state,
                bulkUploadResponse: {},
            };
        // PUT
        case actionTypes.PUT_HOLIDAY_SUCCESS:
            return {
                ...state,
                editHolidayResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PUT_HOLIDAY_FAILURE:
            return {
                ...state,
                editHolidayResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.PUT_HOLIDAY_RESET:
            return {
                ...state,
                editHolidayResponse: {},
            };
        // DELETE
        case actionTypes.DELETE_HOLIDAY_SUCCESS:
            return {
                ...state,
                deleteHolidayResponse: { success: true, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.DELETE_HOLIDAY_FAILURE:
            return {
                ...state,
                deleteHolidayResponse: { success: false, ...action.payload?.data, message: action.payload?.message },
            };
        case actionTypes.DELETE_HOLIDAY_RESET:
            return {
                ...state,
                deleteHolidayResponse: {},
            };

        default:
            return state;
    }
};

export default holidayManagementReducers;
