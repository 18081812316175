export const GET_SUBJECTS_REQUEST = 'GET_SUBJECTS_REQUEST';
export const GET_SUBJECTS_SUCCESS = 'GET_SUBJECTS_SUCCESS';
export const GET_SUBJECTS_FAILURE = 'GET_SUBJECTS_FAILURE';

export const GET_TOPICS_REQUEST = 'GET_TOPICS_REQUEST';
export const GET_TOPICS_SUCCESS = 'GET_TOPICS_SUCCESS';
export const GET_TOPICS_FAILURE = 'GET_TOPICS_FAILURE';

export const GET_RESOURCE_REQUEST = 'GET_RESOURCE_REQUEST';
export const GET_RESOURCE_SUCCESS = 'GET_RESOURCE_SUCCESS';
export const GET_RESOURCE_FAILURE = 'GET_RESOURCE_FAILURE';

export const GET_RESOURCE_BY_ID_REQUEST = 'GET_RESOURCE_BY_ID_REQUEST';
export const GET_RESOURCE_BY_ID_FAILURE = 'GET_RESOURCE_BY_ID_FAILURE';
export const GET_RESOURCE_BY_ID_SUCCESS = 'GET_RESOURCE_BY_ID_SUCCESS';

export const POST_SUBJECTS_REQUEST = 'POST_SUBJECTS_REQUEST';
export const POST_SUBJECTS_SUCCESS = 'POST_SUBJECTS_SUCCESS';
export const POST_SUBJECTS_FAILURE = 'POST_SUBJECTS_FAILURE';
export const POST_SUBJECTS_RESET = 'POST_SUBJECTS_RESET';

export const POST_TOPICS_REQUEST = 'POST_TOPICS_REQUEST';
export const POST_TOPICS_SUCCESS = 'POST_TOPICS_SUCCESS';
export const POST_TOPICS_FAILURE = 'POST_TOPICS_FAILURE';
export const POST_TOPICS_RESET = 'POST_TOPICS_RESET';

export const PUT_TOPICS_REQUEST = 'PUT_TOPICS_REQUEST';
export const PUT_TOPICS_SUCCESS = 'PUT_TOPICS_SUCCESS';
export const PUT_TOPICS_FAILURE = 'PUT_TOPICS_FAILURE';
export const PUT_TOPICS_RESET = 'PUT_TOPICS_RESET';

export const DELETE_TOPICS_REQUEST = 'DELETE_TOPICS_REQUEST';
export const DELETE_TOPICS_SUCCESS = 'DELETE_TOPICS_SUCCESS';
export const DELETE_TOPICS_FAILURE = 'DELETE_TOPICS_FAILURE';
export const DELETE_TOPICS_RESET = 'DELETE_TOPICS_RESET';

export const DELETE_RESOURCE_REQUEST = 'DELETE_RESOURCE_REQUEST';
export const DELETE_RESOURCE_SUCCESS = 'DELETE_RESOURCE_SUCCESS';
export const DELETE_RESOURCE_FAILURE = 'DELETE_RESOURCE_FAILURE';
export const DELETE_RESOURCE_RESET = 'DELETE_RESOURCE_RESET';

export const POST_ASSIGNSUBJECTS_REQUEST = 'POST_ASSIGNSUBJECTS_REQUEST';
export const POST_ASSIGNSUBJECTS_SUCCESS = 'POST_ASSIGNSUBJECTS_SUCCESS';
export const POST_ASSIGNSUBJECTS_FAILURE = 'POST_ASSIGNSUBJECTS_FAILURE';
export const POST_ASSIGNSUBJECTS_RESET = 'POST_ASSIGNSUBJECTS_RESET';

export const DELETE_SUBJECTS_REQUEST = 'DELETE_SUBJECTS_REQUEST';
export const DELETE_SUBJECTS_SUCCESS = 'DELETE_SUBJECTS_SUCCESS';
export const DELETE_SUBJECTS_FAILURE = 'DELETE_SUBJECTS_FAILURE';

export const EDIT_SUBJECTS_REQUEST = 'EDIT_SUBJECTS_REQUEST';
export const EDIT_SUBJECTS_SUCCESS = 'EDIT_SUBJECTS_SUCCESS';
export const EDIT_SUBJECTS_FAILURE = 'EDIT_SUBJECTS_FAILURE';
export const EDIT_SUBJECTS_RESET = 'EDIT_SUBJECTS_RESET';

export const GET_QUESTION_REQUEST = 'GET_QUESTION_REQUEST';
export const GET_QUESTION_SUCCESS = 'GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAILURE = 'GET_QUESTION_FAILURE';
export const GET_QUESTION_RESET = 'GET_QUESTION_RESET';

export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAILURE = 'DELETE_QUESTION_FAILURE';
export const DELETE_QUESTION_RESET = 'DELETE_QUESTION_RESET';

export const GET_PRACTICE_VIDEO_REQUEST = 'GET_PRACTICE_VIDEO_REQUEST';
export const GET_PRACTICE_VIDEO_SUCCESS = 'GET_PRACTICE_VIDEO_SUCCESS';
export const GET_PRACTICE_VIDEO_FAILURE = 'GET_PRACTICE_VIDEO_FAILURE';

export const GET_PRACTICE_VIDEO_BY_PAGE_REQUEST = 'GET_PRACTICE_VIDEO_BY_PAGE_REQUEST';
export const GET_PRACTICE_VIDEO_BY_PAGE_SUCCESS = 'GET_PRACTICE_VIDEO_BY_PAGE_SUCCESS';
export const GET_PRACTICE_VIDEO_BY_PAGE_FAILURE = 'GET_PRACTICE_VIDEO_BY_PAGE_FAILURE';

export const GET_VIDEO_OTP_REQUEST = 'GET_VIDEO_OTP_REQUEST';
export const GET_VIDEO_OTP_SUCCESS = 'GET_VIDEO_OTP_SUCCESS';
export const GET_VIDEO_OTP_FAILURE = 'GET_VIDEO_OTP_FAILURE';

export const POST_VIDEO_REQUEST = 'POST_VIDEO_REQUEST';
export const POST_VIDEO_SUCCESS = 'POST_VIDEO_SUCCESS';
export const POST_VIDEO_FAILURE = 'POST_VIDEO_FAILURE';

export const POST_THUMB_REQUEST = 'POST_THUMB_REQUEST';
export const POST_THUMB_SUCCESS = 'POST_THUMB_SUCCESS';
export const POST_THUMB_FAILURE = 'POST_THUMB_FAILURE';

export const PUT_VIDEO_REQUEST = 'PUT_VIDEO_REQUEST';
export const PUT_VIDEO_SUCCESS = 'PUT_VIDEO_SUCCESS';
export const PUT_VIDEO_FAILURE = 'PUT_VIDEO_FAILURE';

export const DELETE_VIDEO_REQUEST = 'DELETE_VIDEO_REQUEST';
export const DELETE_VIDEO_SUCCESS = 'DELETE_VIDEO_SUCCESS';
export const DELETE_VIDEO_FAILURE = 'DELETE_VIDEO_FAILURE';
export const DELETE_VIDEO_RESET = 'DELETE_VIDEO_RESET';

export const GET_GLOBALRESOURCE_REQUEST = 'GET_GLOBALRESOURCE_REQUEST';
export const GET_GLOBALRESOURCE_SUCCESS = 'GET_GLOBALRESOURCE_SUCCESS';
export const GET_GLOBALRESOURCE_FAILURE = 'GET_GLOBALRESOURCE_FAILURE';

export const POST_ADDHOME_REQUEST = 'POST_ADDHOME_REQUEST';
export const POST_ADDHOME_SUCCESS = 'POST_ADDHOME_SUCCESS';
export const POST_ADDHOME_FAILURE = 'POST_ADDHOME_FAILURE';

export const GET_CLASS_DIVISION_BY_TEACHER_ID_REQUEST = 'GET_CLASS_DIVISION_BY_TEACHER_ID_REQUEST';
export const GET_CLASS_DIVISION_BY_TEACHER_ID_SUCCESS = 'GET_CLASS_DIVISION_BY_TEACHER_ID_SUCCESS';
export const GET_CLASS_DIVISION_BY_TEACHER_ID_FAILURE = 'GET_CLASS_DIVISION_BY_TEACHER_ID_FAILURE';
