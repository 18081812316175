export const GET_ASSIGNED_CLASS_REQUEST = 'GET_ASSIGNED_CLASS_REQUEST';
export const GET_ASSIGNED_CLASS_SUCCESS = 'GET_ASSIGNED_CLASS_SUCCESS';
export const GET_ASSIGNED_CLASS_FAILURE = 'GET_ASSIGNED_CLASS_FAILURE';

export const CREATE_ASSIGNED_CLASS_REQUEST = 'CREATE_ASSIGNED_CLASS_REQUEST';
export const CREATE_ASSIGNED_CLASS_SUCCESS = 'CREATE_ASSIGNED_CLASS_SUCCESS';
export const CREATE_ASSIGNED_CLASS_FAILURE = 'CREATE_ASSIGNED_CLASS_FAILURE';

export const UPDATE_ASSIGNED_CLASS_REQUEST = 'UPDATE_ASSIGNED_CLASS_REQUEST';
export const UPDATE_ASSIGNED_CLASS_SUCCESS = 'UPDATE_ASSIGNED_CLASS_SUCCESS';
export const UPDATE_ASSIGNED_CLASS_FAILURE = 'UPDATE_ASSIGNED_CLASS_FAILURE';

export const DELETE_ASSIGNED_CLASS_REQUEST = 'DELETE_ASSIGNED_CLASS_REQUEST';
export const DELETE_ASSIGNED_CLASS_SUCCESS = 'DELETE_ASSIGNED_CLASS_SUCCESS';
export const DELETE_ASSIGNED_CLASS_FAILURE = 'DELETE_ASSIGNED_CLASS_FAILURE';

export const GET_ALL_TEACHERS_REQUEST = 'GET_ALL_TEACHERS_REQUEST';
export const GET_ALL_TEACHERS_SUCCESS = 'GET_ALL_TEACHERS_SUCCESS';
export const GET_ALL_TEACHERS_FAILURE = 'GET_ALL_TEACHERS_FAILURE';
