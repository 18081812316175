import * as actionTypes from './constants';

const initialState = {
    zoomData: {},
    getSessionResourcesResponse: {},
    getSessionCustomResourcesResponse: {},
    getSessionAssesmentResponse: {},
    getSessionResultResponse: {},
    getSessionAttendanceResponse: {},
};

const interactiveReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_ZOOM_DATA:
            return {
                ...state,
                zoomData: {
                    ...action.payload,
                },
            };
        case actionTypes.RESET_ZOOM_DATA:
            return {
                ...state,
                zoomData: {},
            };
        case actionTypes.GET_SESSION_RESOURCE_SUCCESS:
            return {
                ...state,
                getSessionResourcesResponse: {
                    success: true,
                    ...action.payload.data.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_SESSION_RESOURCE_FAILURE:
            return {
                ...state,
                getSessionResourcesResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_SESSION_CUSTOM_RESOURCE_SUCCESS:
            return {
                ...state,
                getSessionCustomResourcesResponse: {
                    success: true,
                    ...action.payload.data.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_SESSION_CUSTOM_RESOURCE_FAILURE:
            return {
                ...state,
                getSessionCustomResourcesResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_SESSION_ASSESMENT_SUCCESS:
            return {
                ...state,
                getSessionAssesmentResponse: {
                    success: true,
                    ...action.payload.data.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_SESSION_ASSESMENT_FAILURE:
            return {
                ...state,
                getSessionAssesmentResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.GET_SESSION_RESULT_SUCCESS:
            return {
                ...state,
                getSessionResultResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_SESSION_RESULT_FAILURE:
            return {
                ...state,
                getSessionResultResponse: { success: false, ...action.payload?.data },
            };
        case actionTypes.GET_SESSION_ATTENDANCE_SUCCESS:
            return {
                ...state,
                getSessionAttendanceResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_SESSION_ATTENDANCE_FAILURE:
            return {
                ...state,
                getSessionAttendanceResponse: { success: false, ...action.payload?.data },
            };
        default:
            return state;
    }
};

export default interactiveReducers;
