import * as actionTypes from './constants';

const initialState = {
    getProfileResponse: {},
    updateProfileResponse: {},
    resetPasswordResponse: {},
};

const generalSettingsReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROFILE_SUCCESS:
            return {
                ...state,
                getProfileResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_PROFILE_FAILURE:
            return {
                ...state,
                getProfileResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                updateProfileResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.UPDATE_PROFILE_FAILURE:
            return {
                ...state,
                updateProfileResponse: { success: false, ...action.payload?.data?.data },
            };
        case actionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                resetPasswordResponse: {
                    success: true,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        case actionTypes.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                resetPasswordResponse: {
                    success: false,
                    ...action.payload?.data?.data,
                    message: action.payload.message,
                },
            };
        default:
            return state;
    }
};

export default generalSettingsReducers;
