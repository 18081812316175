export const GET_ROLE_PROFILES_REQUEST = 'GET_ROLE_PROFILES_REQUEST';
export const GET_ROLE_PROFILES_SUCCESS = 'GET_ROLE_PROFILES_SUCCESS';
export const GET_ROLE_PROFILES_FAILURE = 'GET_ROLE_PROFILES_FAILURE';

export const GET_PERMISSIONS_REQUEST = 'GET_PERMISSIONS_REQUEST';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILURE = 'GET_PERMISSIONS_FAILURE';

export const CREATE_ROLE_PERMISSION_REQUEST = 'CREATE_ROLE_PERMISSION_REQUEST';
export const CREATE_ROLE_PERMISSION_SUCCESS = 'CREATE_ROLE_PERMISSION_SUCCESS';
export const CREATE_ROLE_PERMISSION_FAILURE = 'CREATE_ROLE_PERMISSION_FAILURE';
// list roles
export const SEND_GETROLES_REQUEST = 'SEND_GETROLES_REQUEST';
export const SEND_GETROLES_SUCCESS = 'SEND_GETROLES_SUCCESS';
export const SEND_GETROLES_FAILURE = 'SEND_GETROLES_FAILURE';
export const SEND_GETROLES_RESET = 'SEND_GETROLES_RESET';
// get role permissions by Id
export const GET_ROLEPERMISSION_REQUEST = 'GET_ROLEPERMISSION_REQUEST';
export const GET_ROLEPERMISSION_SUCCESS = 'GET_ROLEPERMISSION_SUCCESS';
export const GET_ROLEPERMISSION_FAILURE = 'GET_ROLEPERMISSION_FAILURE';
export const GET_ROLEPERMISSION_RESET = 'GET_ROLEPERMISSION_RESET';

export const UPDATE_ROLE_PERMISSION_REQUEST = 'UPDATE_ROLE_PERMISSION_REQUEST';
export const UPDATE_ROLE_PERMISSION_SUCCESS = 'UPDATE_ROLE_PERMISSION_SUCCESS';
export const UPDATE_ROLE_PERMISSION_FAILURE = 'UPDATE_ROLE_PERMISSION_FAILURE';

export const DELETE_ROLE_PERMISSION_REQUEST = 'DELETE_ROLE_PERMISSION_REQUEST';
export const DELETE_ROLE_PERMISSION_SUCCESS = 'DELETE_ROLE_PERMISSION_SUCCESS';
export const DELETE_ROLE_PERMISSION_FAILURE = 'DELETE_ROLE_PERMISSION_FAILURE';

export const VALIDATE_ROLE_REQUEST = 'VALIDATE_ROLE_REQUEST';
export const VALIDATE_ROLE_SUCCESS = 'VALIDATE_ROLE_SUCCESS';
export const VALIDATE_ROLE_FAILURE = 'VALIDATE_ROLE_FAILURE';
