import React from 'react';
import { Navigate } from 'react-router-dom';
import Header from '../components/common/Header';
import SideBar from '../components/common/SideBar';
import SchoolHeader from '../components/common/SchoolHeader';

function PrivateRoute({ children, isLoggedIn }) {
    const { userDetails } = JSON.parse(sessionStorage.getItem('login-Data'))?.data || {};

    return isLoggedIn ? (
        <div className="page-wrapper">
            <div className="page-wrapper-grid">
                <SideBar />
                <div className="content-page">
                    <Header />
                    {userDetails?.role?.profile_name !== 'SCHOOL' && <SchoolHeader />}
                    {children}
                </div>
            </div>
        </div>
    ) : (
        <Navigate to="/sign-in" />
    );
}

export default PrivateRoute;
