import * as actionTypes from './constants';

const initialState = {
    createUserResponse: {},
    deleteUserResponse: {},
    getUserByIdResponse: {},
    updateUserResponse: {},
    getUsersResponse: {},
    patchUserResponse: {},
};

const userManagementReducers = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USERS_SUCCESS:
            return {
                ...state,
                getUsersResponse: { success: true, ...action.payload?.data },
            };
        case actionTypes.GET_USERS_FAILURE:
            return {
                ...state,
                getUsersResponse: { success: false, ...action.payload?.data },
            };

        case actionTypes.CREATE_USER_SUCCESS:
            return {
                ...state,
                createUserResponse: { success: true, ...action.payload?.data?.data, message: action.payload.message },
            };
        case actionTypes.CREATE_USER_FAILURE:
            return {
                ...state,
                createUserResponse: { success: false, message: action.payload.message, ...action.payload?.data?.data },
            };

        case actionTypes.DELETE_USER_SUCCESS:
            return {
                ...state,
                deleteUserResponse: { success: true, ...action.payload?.data?.data, message: action.payload.message },
            };
        case actionTypes.DELETE_USER_FAILURE:
            return {
                ...state,
                deleteUserResponse: { success: false, ...action.payload?.data?.data, message: action.payload.message },
            };

        case actionTypes.GET_USER_BY_ID_SUCCESS:
            return {
                ...state,
                getUserByIdResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.GET_USER_BY_ID_FAILURE:
            return {
                ...state,
                getUserByIdResponse: { success: false, ...action.payload?.data?.data },
            };

        case actionTypes.UPDATE_USER_SUCCESS:
            return {
                ...state,
                updateUserResponse: { success: true, ...action.payload?.data?.data, message: action.payload.message },
            };
        case actionTypes.UPDATE_USER_FAILURE:
            return {
                ...state,
                updateUserResponse: { success: false, ...action.payload?.data?.data, message: action.payload.message },
            };

        case actionTypes.PATCH_USER_SUCCESS:
            return {
                ...state,
                patchUserResponse: { success: true, ...action.payload?.data?.data },
            };
        case actionTypes.PATCH_USER_FAILURE:
            return {
                ...state,
                patchUserResponse: { success: false, ...action.payload?.data?.data },
            };
        default:
            return state;
    }
};

export default userManagementReducers;
