export const GET_ALLTOPICS_REQUEST = 'GET_ALLTOPICS_REQUEST';
export const GET_ALLTOPICS_SUCCESS = 'GET_ALLTOPICS_SUCCESS';
export const GET_ALLTOPICS_FAILURE = 'GET_ALLTOPICS_FAILURE';

export const GET_RESOURCETYPE_REQUEST = 'GET_RESOURCETYPE_REQUEST';
export const GET_RESOURCETYPE_SUCCESS = 'GET_RESOURCETYPE_SUCCESS';
export const GET_RESOURCETYPE_FAILURE = 'GET_RESOURCETYPE_FAILURE';

export const GET_RESOURCESUBTYPE_REQUEST = 'GET_RESOURCESUBTYPE_REQUEST';
export const GET_RESOURCESUBTYPE_SUCCESS = 'GET_RESOURCESUBTYPE_SUCCESS';
export const GET_RESOURCESUBTYPE_FAILURE = 'GET_RESOURCETYPE_FAILURE';

export const GET_QUESTION_BY_ID_REQUEST = 'GET_QUESTION_BY_ID_REQUEST';
export const GET_QUESTION_BY_ID_SUCCESS = 'GET_QUESTION_BY_ID_SUCCESS';
export const GET_QUESTION_BY_ID_FAILURE = 'GET_QUESTION_BY_ID_FAILURE';

export const POST_RESOURCE_REQUEST = 'POST_RESOURCE_REQUEST';
export const POST_RESOURCE_SUCCESS = 'POST_RESOURCE_SUCCESS';
export const POST_RESOURCE_FAILURE = 'POST_RESOURCE_FAILURE';
export const POST_RESOURCE_RESET = 'POST_RESOURCE_RESET';

export const PATCH_RESOURCE_REQUEST = 'PATCH_RESOURCE_REQUEST';
export const PATCH_RESOURCE_SUCCESS = 'PATCH_RESOURCE_SUCCESS';
export const PATCH_RESOURCE_FAILURE = 'PATCH_RESOURCE_FAILURE';
export const PATCH_RESOURCE_RESET = 'PATCH_RESOURCE_RESET';

export const POST_QUESTION_REQUEST = 'POST_QUESTION_REQUEST';
export const POST_QUESTION_SUCCESS = 'POST_QUESTION_SUCCESS';
export const POST_QUESTION_FAILURE = 'POST_QUESTION_FAILURE';
export const POST_QUESTION_RESET = 'POST_QUESTION_RESET';

export const PATCH_QUESTION_REQUEST = 'PATCH_QUESTION_REQUEST';
export const PATCH_QUESTION_SUCCESS = 'PATCH_QUESTION_SUCCESS';
export const PATCH_QUESTION_FAILURE = 'PATCH_QUESTION_FAILURE';
export const PATCH_QUESTION_RESET = 'PATCH_QUESTION_RESET';

export const POST_CREATECOUNT_REQUEST = 'POST_CREATECOUNT_REQUEST';
export const POST_CREATECOUNT_SUCCESS = 'POST_CREATECOUNT_SUCCESS';
export const POST_CREATECOUNT_FAILURE = 'POST_CREATECOUNT_FAILURE';
export const POST_CREATECOUNT_RESET = 'POST_CREATECOUNT_RESET';

export const PATCH_EDITCOUNT_REQUEST = 'PATCH_EDITCOUNT_REQUEST';
export const PATCH_EDITCOUNT_SUCCESS = 'PATCH_EDITCOUNT_SUCCESS';
export const PATCH_EDITCOUNT_FAILURE = 'PATCH_EDITCOUNT_FAILURE';
export const PATCH_EDITCOUNT_RESET = 'PATCH_EDITCOUNT_RESET';

export const POST_CREATESORT_REQUEST = 'POST_CREATESORT_REQUEST';
export const POST_CREATESORT_SUCCESS = 'POST_CREATESORT_SUCCESS';
export const POST_CREATESORT_FAILURE = 'POST_CREATESORT_FAILURE';
export const POST_CREATESORT_RESET = 'POST_CREATESORT_RESET';

export const PATCH_EDITSORT_REQUEST = 'PATCH_EDITSORT_REQUEST';
export const PATCH_EDITSORT_SUCCESS = 'PATCH_EDITSORT_SUCCESS';
export const PATCH_EDITSORT_FAILURE = 'PATCH_EDITSORT_FAILURE';
export const PATCH_EDITSORT_RESET = 'PATCH_EDITSORT_RESET';

export const POST_CREATEMATCH_REQUEST = 'POST_CREATEMATCH_REQUEST';
export const POST_CREATEMATCH_SUCCESS = 'POST_CREATEMATCH_SUCCESS';
export const POST_CREATEMATCH_FAILURE = 'POST_CREATEMATCH_FAILURE';
export const POST_CREATEMATCH_RESET = 'POST_CREATEMATCH_RESET';

export const PUT_EDITMATCH_REQUEST = 'PUT_EDITMATCH_REQUEST';
export const PUT_EDITMATCH_SUCCESS = 'PUT_EDITMATCH_SUCCESS';
export const PUT_EDITMATCH_FAILURE = 'PUT_EDITMATCH_FAILURE';
export const PUT_EDITMATCH_RESET = 'PUT_EDITMATCH_RESET';

export const POST_CREATECIRCLE_REQUEST = 'POST_CREATECIRCLE_REQUEST';
export const POST_CREATECIRCLE_SUCCESS = 'POST_CREATECIRCLE_SUCCESS';
export const POST_CREATECIRCLE_FAILURE = 'POST_CREATECIRCLE_FAILURE';
export const POST_CREATECIRCLE_RESET = 'POST_CREATECIRCLE_RESET';

export const PATCH_EDITCIRCLE_REQUEST = 'PATCH_EDITCIRCLE_REQUEST';
export const PATCH_EDITCIRCLE_SUCCESS = 'PATCH_EDITCIRCLE_SUCCESS';
export const PATCH_EDITCIRCLE_FAILURE = 'PATCH_EDITCIRCLE_FAILURE';
export const PATCH_EDITCIRCLE_RESET = 'PATCH_EDITCIRCLE_RESET';

export const POST_CREATECOLOUR_REQUEST = 'POST_CREATECOLOUR_REQUEST';
export const POST_CREATECOLOUR_SUCCESS = 'POST_CREATECOLOUR_SUCCESS';
export const POST_CREATECOLOUR_FAILURE = 'POST_CREATECOLOUR_FAILURE';
export const POST_CREATECOLOUR_RESET = 'POST_CREATECOLOUR_RESET';

export const PATCH_EDITCOLOUR_REQUEST = 'PATCH_EDITCOLOUR_REQUEST';
export const PATCH_EDITCOLOUR_SUCCESS = 'PATCH_EDITCOLOUR_SUCCESS';
export const PATCH_EDITCOLOUR_FAILURE = 'PATCH_EDITCOLOUR_FAILURE';
export const PATCH_EDITCOLOUR_RESET = 'PATCH_EDITCOLOUR_RESET';
