export const ADD_LOADER = 'ADD_LOADER';
export const REMOVE_LOADER = 'REMOVE_LOADER';
export const UPDATE_SIDEBAR_REQUEST = 'UPDATE_SIDEBAR_REQUEST';
export const UPDATE_SIDEBAR_SUCCESS = 'UPDATE_SIDEBAR_SUCCESS';
export const UPDATE_SIDEBAR_FAILURE = 'UPDATE_SIDEBAR_FAILURE';

export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_SIDEBAR_ON = 'TOGGLE_SIDEBAR_ON';
export const TOGGLE_SIDEBAR_OFF = 'TOGGLE_SIDEBAR_OFF';

export const GET_BREADCRUMB_DATA_SUCCESS = 'GET_BREADCRUMB_DATA_SUCCESS';
export const GET_BREADCRUMB_DATA_FAILURE = 'GET_BREADCRUMB_DATA_FAILURE';
